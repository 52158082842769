import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "../routes";

export default function MainPage() {
  return (
    <div>
      <Suspense>
        <Routes>
          {routes?.filter(c => c?.element)?.map((route, idx) => <Route key={idx} path={route?.path} exact={route?.exact} name={route?.name} element={<route.element />} />)}
          <Route path="*" element={<Navigate to="notfound" replace />} />
        </Routes>
      </Suspense>
    </div>
  );
}

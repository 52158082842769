import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CForm,
  CFormInput,
} from "@coreui/react";
import React from "react";
import ArrowBack from "../../../assets/images/navbar/arrow-back.png";

export default function OtpVerification({
  values,
  handleChange,
  handleSubmit,
  time,
  email,
  handleResendVerificationCode,
  digit1Ref,
  digit2Ref,
  digit3Ref,
  digit4Ref,
  handleClearGoBack,
  errors,
  loading,
  allDigitsFilled,
}) {
  return (
    <div className="Container-fluid login">
      <div className="back-btn">
        <a href="#" onClick={handleClearGoBack}>
          <span>
            <img src={ArrowBack} />
          </span>{" "}
          Back
        </a>
      </div>
      <div
        className="d-flex align-items-center justify-content-center next"
        style={{ padding: "0 300px" }}
      >
        <CCard>
          <CCardHeader>Two - Factor Authentication</CCardHeader>
          <CCardBody className="py-4 px-5">
            <div className="logo-sec">
              <p>{`Please enter the 4 digit code that was send to ${atob(
                email
              )}`}</p>
            </div>
            <CForm>
              <div className="input-text otp mt-3 mb-4">
                <CFormInput
                  type="text"
                  autofocus="autofocus"
                  className=""
                  name="digit1"
                  id="digit1"
                  value={values?.digit1}
                  onChange={handleChange}
                  maxLength={1}
                  ref={digit1Ref}
                  disabled={loading}
                />
                <CFormInput
                  type="text"
                  autofocus="autofocus"
                  className=""
                  name="digit2"
                  id="digit2"
                  value={values?.digit2}
                  onChange={handleChange}
                  maxLength={1}
                  ref={digit2Ref}
                  disabled={loading}
                />
                <CFormInput
                  type="text"
                  autofocus="autofocus"
                  className=""
                  name="digit3"
                  id="digit3"
                  value={values?.digit3}
                  onChange={handleChange}
                  maxLength={1}
                  ref={digit3Ref}
                  disabled={loading}
                />
                <CFormInput
                  type="text"
                  autofocus="autofocus"
                  className=""
                  name="digit4"
                  id="digit4"
                  value={values?.digit4}
                  onChange={handleChange}
                  maxLength={1}
                  ref={digit4Ref}
                  disabled={loading}
                />
              </div>
              {errors?.verification_code ? (
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginLeft: "36%",
                  }}
                >
                  {errors?.verification_code}
                </span>
              ) : (
                false
              )}
              {errors?.autherror ? (
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginLeft: "36%",
                  }}
                >
                  {errors?.autherror}
                </span>
              ) : (
                false
              )}
              <div className="buttons mx-5">
                <CButton
                  type="submit"
                  onClick={handleSubmit}
                  disabled={loading || !allDigitsFilled}
                >
                  {loading ? "Please Wait..." : "Submit"}
                </CButton>
              </div>

              <div className="forgot mt-5">
                <a
                  className="d-flex align-items-center fw-bold"
                  style={{ color: "#0E0000" }}
                >
                  Time remaining{" "}
                  <span style={{ color: "#A31D2A" }}>
                    <p className="timer mb-0 ms-2">
                      {time?.minutes < 10 ? `0${time?.minutes}` : time?.minutes}
                      :
                      {time?.seconds < 10 ? `0${time?.seconds}` : time?.seconds}
                    </p>
                  </span>
                </a>
                {time?.seconds ? (
                  <a
                    style={{
                      color: "#7C8189",
                      textDecoration: "underline",
                      fontWeight: "400",
                      cursor: "wait",
                    }}
                  >
                    Resend code
                  </a>
                ) : (
                  <a
                    style={{
                      color: "#7C8189",
                      textDecoration: "underline",
                      fontWeight: "400",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleResendVerificationCode();
                    }}
                  >
                    Resend code
                  </a>
                )}
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </div>
    </div>
  );
}

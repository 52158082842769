import APISources from "../sources"
import instance from "../utils/axios";


export const getServicesAsync = async (data, dispatch) => await instance.post(`${APISources().Services.ListURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const createServiceAsync = async (data, dispatch) => await instance.post(`${APISources().Services.CreateURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getServiceDetailsAsync = async (data, dispatch) => await instance.post(`${APISources().Services.DetailsURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const updateServiceAsync = async (data, dispatch) => await instance.post(`${APISources().Services.UpdateURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const deleteServiceAsync = async (data, dispatch) => await instance.post(`${APISources().Services.DeleteURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getAddedServicesAsync = async (data, dispatch) => await instance.post(`${APISources().Services.AddedServicesURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const serviceStatusAsync = async (data, dispatch) => await instance.post(`${APISources().Services.ServiceStatusURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getSoftwareAsync = async (data, dispatch) => await instance.get(`${APISources().Services.SoftwareDetailURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
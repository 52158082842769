import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBranchesAsync,
  createBranchAsync,
  getBranchDetailsAsync,
  updateBranchAsync,
  deleteBranchAsync,
  getCustomersAsync,
  updateBranchStatusAsync,
  getBranchLeadAsync,
  getBranchFilterAsync,
  listFilterCustomersAsync,
  listFilterBranchAsync,
  listFilterLeadAsync,
  getScopeOfWorkAsync,
  listFilterPartnerAsync,
  listFilterAgentsAsync

} from "../api/branches";

const BranchContext = createContext();

export const BranchProvider = ({ children }) => {
  const dispatch = useDispatch();

  const getBranches = async (data) => await getBranchesAsync(data, dispatch);
  const getBranchLead = async (data) => await getBranchLeadAsync(data, dispatch);
  const getBranchFilter = async (data) => await getBranchFilterAsync(data, dispatch);
  const createBranch = async (data) =>
    await createBranchAsync(data, dispatch);

  const getBranchDetails = async (data) => await getBranchDetailsAsync(data, dispatch);
  const getScopeOfWork = async (data) => await getScopeOfWorkAsync(data, dispatch);

  const updateBranch = async (data) => await updateBranchAsync(data, dispatch);

  const deleteBranch = async (data) => await deleteBranchAsync(data, dispatch);

  const getCustomers = async (data) => await getCustomersAsync(data, dispatch);

  const updateBranchStatus = async (data) => await updateBranchStatusAsync(data, dispatch);

  const listFilterCustomers = async (data) => await listFilterCustomersAsync(data, dispatch);

  const listFilterBranch = async (data) => await listFilterBranchAsync(data, dispatch);

  const listFilterLead = async (data) => await listFilterLeadAsync(data, dispatch);

  const listFilterPartner = async (data)=> await listFilterPartnerAsync(data,dispatch);

  const listFilterAgents = async (data)=> await listFilterAgentsAsync(data,dispatch);

  const values = {
    getScopeOfWork,
    getBranches,
    createBranch,
    getBranchDetails,
    updateBranch,
    deleteBranch,
    getCustomers,
    updateBranchStatus,
    getBranchLead,
    getBranchFilter,
    listFilterCustomers,
    listFilterBranch,
    listFilterLead,
    listFilterPartner,
    listFilterAgents
  };

  return (
    <BranchContext.Provider value={values}>{children}</BranchContext.Provider>
  );
};

export default BranchContext;

import APISources from "../sources"
import { apiCall, getUrl } from "./apicall";

//Chart Details
export const getDashboardChartDetailsAsync = (data) => getUrl("subscriptionChartUrl", data);
//Filter partner, customer, branch
export const getDashboardPartnerAsync = (data) => getUrl("partnersUrl", data);
export const getDashboardBranchesAsync = (data) => apiCall("post", `${APISources().Dashboard.ListBranchesURI}`, data);
export const getDashboardCustomersAsync = (data) => getUrl("customersUrl", data)
export const getAgentListAsync = (data) => getUrl("agentUrl", data)
//Count Details
export const getDashboardCountAsync = (data) => getUrl("countDetailsUrl", data)
//Nps Chart
export const getDashboardNpsChartAsync = (data) => getUrl("npsDashboardUrl", data)
//Listing Customer, Billing, Review
export const getDashboardTopCustomerAsync = (data) => getUrl("top5CustomersUrl", data)
export const getDashboardLatestReviewAsync = (data) => getUrl("latestReviewsUrl", data)
export const getTopFiveBillingsBAsync = (data) => getUrl("top5BillingsUrl", data)
//Customer Dashboard Due Today, In-Review, Work-In-Progress
export const getDashboardAsync = (data) => apiCall("post", `${APISources().Dashboard?.ListDashboardURL}`, data)
//Invoice
export const getDashboardInvoiceAsync = (data) => getUrl("dashboardInvoiceUrl", data);
export const getDashboardInvoiceSummaryAsync = (data) => getUrl("invoiceGraphUrl", data);
//Storage Chart
export const getStorageChartAsync = (data) => apiCall("post", `${APISources().Dashboard?.StorageChartURI}`, data)
//Progress chart
export const getProgressChartAsync = (data) => getUrl("projectStatusUrl", data)
//user service
export const getUserServiceAsync = (data) => getUrl("userServiceUrl", data)
//Project count
export const getProjectDashboardAsync = (data) => getUrl("projectDashboardUrl", data)





import React, { createContext } from "react";
import { useDispatch } from "react-redux";
import { getErrorReportsAsync, downloadErrorReportAsync, getReportsAsync, getProjectReportsAsync, exportCSVProjectsListAsync, exportPDFAddedProjectsListAsync, exportPDFProjectsListAsync, getAddedProjectReportsAsync, exportCSVAddedProjectsListAsync, ListFilterPartnerAsync, listFilterCustomersAsync, ListFilterBranchAsync, exportDelayedProjectsListAsync, getServiceAsync, getAgentListAsync } from "../api/reports";

const ReportsContext = createContext();

export const ReportsProvider = ({ children }) => {
  const dispatch = useDispatch();

  const getReports = async (data) => await getReportsAsync(data, dispatch);

  const getProjectReports = async (data) => await getProjectReportsAsync(data, dispatch);

  const getErrorReports = async (data) => await getErrorReportsAsync(data, dispatch);
  const downloadErrorReport = async (data) => await downloadErrorReportAsync(data, dispatch);

  const exportPDFProjectsList = async (data) => await exportPDFProjectsListAsync(data, dispatch);

  const exportCSVProjectsList = async (data) => await exportCSVProjectsListAsync(data, dispatch);

  const getAddedProjectReports = async (data) => await getAddedProjectReportsAsync(data, dispatch);

  const exportAddedPDFProjectsList = async (data) => await exportPDFAddedProjectsListAsync(data, dispatch);

  const exportAddedCSVProjectsList = async (data) => await exportCSVAddedProjectsListAsync(data, dispatch);

  const exportDelayedProjectList = async (data) => await exportDelayedProjectsListAsync(data, dispatch);

  const ListFilterPartner = async (data) => await ListFilterPartnerAsync(data, dispatch);

  const listFilterCustomers = async (data) => await listFilterCustomersAsync(data, dispatch);

  const ListFilterBranch = async (data) => await ListFilterBranchAsync(data, dispatch);

  const getService = async (data) => await getServiceAsync(data, dispatch);

  const getAgentList = async (data) => await getAgentListAsync(data, dispatch);

  const values = {
    getErrorReports,
    downloadErrorReport,
    getReports,
    getProjectReports,
    exportPDFProjectsList,
    exportCSVProjectsList,
    getAddedProjectReports,
    exportAddedPDFProjectsList,
    exportAddedCSVProjectsList,
    exportDelayedProjectList,
    ListFilterPartner,
    listFilterCustomers,
    ListFilterBranch,
    getService,
    getAgentList
  };

  return (
    <ReportsContext.Provider value={values}>
      {children}
    </ReportsContext.Provider>
  );
};

export default ReportsContext;

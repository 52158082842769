import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadCaptchaEnginge, validateCaptcha } from "react-simple-captcha";
import config from '../../../config';
import useAuth from "../../../hooks/useAuth";
import LoginForm from "./loginForm";

export default function Login() {
  const { authLogin } = useAuth();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)
  const [values, setValues] = useState({
    email: '',
    password: ''
  })

  const handleChange = (e) => {
    setErrors({})
    const values_ = { ...values }
    values_[e.target.id] = e.target.value
    setValues(values_)
  }

  //CAPTCHA
  useEffect(() => {
    loadCaptchaEnginge(6, '#fff')
  }, [])

  const handleToken = (token) => {
    if (!token) return
    localStorage.setItem(config?.tokenVar, btoa(token))
  }

  const handleNext = (response) => {
    const errors_ = {}
    handleToken(response?.data?.token)
    switch (response?.data?.status) {
      case 200: return window.location.replace(`/`) // 2f2a disabled
      case 205: return window.location.replace(`/forgot-password/`) // force_password_reset
      case 206: return window.location.replace(`/otpVerification/${btoa(values?.email)}/${response?.data?.customer_id}`) // 2f2a enabled
      default: errors_['autherror'] = response?.data?.messages
    }
    setErrors(errors_)
  }

  const handleSubmit = async (e) => {
    e.stopPropagation()
    // validate user data
    const errors_ = {}
    errors_['email'] = !values?.email ? "Email is required" : ''
    errors_['password'] = !values?.password ? "Password is required" : ''
    // check captcha
    const captchaInput = document.getElementById('captcha_input')?.value
    if (validateCaptcha(captchaInput)) setIsFormValid(true)
    else errors_['captcha'] = "Invalid Captcha"
    setErrors(errors_)
    // check if errors, if errors return
    if (Object.values(errors_).find(_ => _)) return
    // encrypt
    setLoading(true)
    const password = btoa(values?.password?.trim())
    const email = btoa(values?.email)
    const remeber_me = localStorage.getItem(btoa(config.remeber_me_token))

    // login
    await authLogin({ email: email, password: password,remeber_me:remeber_me }).then(response => handleNext(response)).catch(_ => setLoading(false)).finally(_ => setLoading(false))
  }

  const handleRemeberMe = (e) => {
    if(e.target.checked) return localStorage.setItem(btoa(config.remeber_me_token), true)
    localStorage.removeItem(btoa(config.remeber_me_token))
  }

  return <LoginForm values={values} loading={loading} handleChange={handleChange} errors={errors} handleSubmit={handleSubmit} isFormValid={isFormValid ? isFormValid : ''} handleRemeberMe={handleRemeberMe}/>
}
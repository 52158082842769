import APISources from "../sources"
import instance from "../utils/axios";


export const getBranchesAsync = async (data, dispatch) => await instance.post(`${APISources().Branches?.ListURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getScopeOfWorkAsync = async (data, dispatch) => await instance.post(`${APISources().Branches?.GetScopeOfWorkURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const createBranchAsync = async (data, dispatch) => await instance.post(`${APISources().Branches.CreateURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getBranchDetailsAsync = async (data, dispatch) => await instance.post(`${APISources().Branches.DetailsURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const updateBranchAsync = async (data, dispatch) => await instance.post(`${APISources().Branches.UpdateURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const deleteBranchAsync = async (data, dispatch) => await instance.post(`${APISources().Branches.DeleteURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getCustomersAsync = async (data, dispatch) => await instance.post(`${APISources().Branches.CustomersURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getBranchLeadAsync = async (data, dispatch) => await instance.post(`${APISources().Branches.BranchLeadURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getBranchFilterAsync = async (data, dispatch) => await instance.post(`${APISources().Branches.BranchFilterList}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const updateBranchStatusAsync = async (data, dispatch) => await instance.post(`${APISources().Branches.BranchStatusURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const listFilterCustomersAsync = async (data, dispatch) => await instance.post(`${APISources().Branches.ListFilterCustomersURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const listFilterBranchAsync = async (data, dispatch) => await instance.post(`${APISources().Branches.ListFilterBranchURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const listFilterLeadAsync = async (data, dispatch) => await instance.post(`${APISources().Branches.ListFilterLeadURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const listFilterPartnerAsync = async (data,dispatch)=> await instance.post(`${APISources().Branches.ListFilterPartnerURI}`,data,{signal: data?.signal, cancelToken:data?.cancelToken})
export const listFilterAgentsAsync = async (data,dispatch) => await instance.post(`${APISources().Branches.ListFilterAgentsURI}`,data,{signal: data?.signal, cancelToken: data?.cancelToken})


import { CButton, CCard, CCardBody, CCardHeader, CCol, CForm, CFormCheck, CFormInput, CFormLabel, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import ArrowBack from '../../../assets/images/navbar/arrow-back.png';
import { Link } from 'react-router-dom';

export default function ForgotPassword({ values, handleChange, errors, handleSubmit, loading }) {



    return (
        <div className="Container-fluid login">
            <div className='back-btn'><a href='/login/'><span><img src={ArrowBack} /></span> Back</a></div>
            <div className='d-flex align-items-center justify-content-center next' style={{ padding: "0 300px" }}>
                <CCard>
                    <CCardHeader>Forgot Password</CCardHeader>
                    <CCardBody className='py-4 px-5'>
                        <div className="logo-sec mx-5">
                            <p>Please enter your email address to <br />receive a verification code</p>
                        </div>
                        <CForm onSubmit={e => e.preventDefault()}>
                            <div className="input-text">
                                <CFormLabel>Email</CFormLabel>
                                <CFormInput type="text" className="text-transform-normal" placeholder="Enter email id" name="email" id='email' value={values?.email} onChange={handleChange} />
                            </div>
                            {errors?.email || errors?.autherror?
                                <span style={{ color: 'red', fontSize: '12px', marginLeft: '36%' }}>{errors?.email || errors?.autherror}</span>
                                : false}
                            <div className="buttons mt-3">
                                <CButton type="submit" onClick={handleSubmit}>{loading ? `Please wait...` : 'Send'}</CButton>
                            </div>
                        </CForm>
                    </CCardBody>
                </CCard>
            </div>
        </div>
    )
}

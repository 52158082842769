import APISources from "../sources"
import instance from "../utils/axios";
import * as apiUrls from "../lambdaApiUrls";

export const getInvoicesAsync = async (data, dispatch) => await instance.get(`${apiUrls?.invoiceUrl}?customer_id=${data?.customer_id}&partner=${data?.partner}&agent=${data?.agent}&branch=${data?.branch}&year=${data?.year}&month=${data?.month}&page_number=${data?.page_number}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })

export const getInvoicesViewAsync = async (data, dispatch) => await instance.get(`${apiUrls?.invoiceViewUrl}?id=${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })

export const downloadInvoiceAsync = async (data, dispatch) => await instance.post(`${apiUrls?.downloadInvoiceUrl}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })

export const sendInvoiceAsync = async (data, dispatch) => await instance.post(`${apiUrls?.sendInvoiceUrl}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })

export const listFilterCustomersURIAsync = async (data, dispatch) => await instance.get(`${apiUrls?.customerUrl}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })

export const listPartnerToCustomerFiltersAsync = async (data, dispatch) => await instance.get(`${apiUrls?.customerUrl}?partner=${data?.partner}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })

export const listFilterMonthYearURIAsync = async (data, dispatch) => await instance.get(`${apiUrls?.monthYearUrl}`, { signal: data?.signal, cancelToken: data?.cancelToken })

import APISources from "../sources"
import instance from "../utils/axios";
import * as apiUrls from "../lambdaApiUrls";

export const createAgentAsync = async (data, dispatch) => await instance.post(`${APISources().Agent.CreateURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getAgentAsync = async (data, dispatch) => await instance.get(`${APISources().Agent.DetailsURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const updateAgentAsync = async (data, dispatch) => await instance.post(`${APISources().Agent.UpdateURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const deleteAgentAsync = async (data, dispatch) => await instance.post(`${APISources().Agent.DeleteURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getPartnersAsync = async (data, dispatch) => await instance.post(`${APISources().Agent.PartnersURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getPartnerSubscriptionsAsync = async (data, dispatch) => await instance.post(`${APISources().Agent.PartnerSubscriptionsURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const uploadAgentContractFileAsync = async (data, dispatch) => await instance.post(`${APISources().Agent.UploadFileURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const updateAgentStatusAsync = async (data, dispatch) => await instance.post(`${APISources().Agent.AgentStatusURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getSoftwaresByServiceAsync = async (data, dispatch) => await instance.post(`${APISources().Agent.SoftwaresByServiceURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })

//Lambda
export const getAgentListAsync = async (data, dispatch) => await instance.get(`${apiUrls?.agentUrl}?pageNumber=${data?.pageNumber}&partner=${data?.partner}&status=${data?.status}&search=${data?.search}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
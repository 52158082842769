import React, { createContext } from "react";
import { useDispatch } from "react-redux";
import { getSoftwaresByServiceAsync, getAgentListAsync, updateAgentStatusAsync, uploadAgentContractFileAsync, createAgentAsync, getAgentAsync, updateAgentAsync, deleteAgentAsync, getPartnersAsync, getPartnerSubscriptionsAsync } from "../api/agent";
const AgentContext = createContext();

export const AgentProvider = ({ children }) => {
    const dispatch = useDispatch();
    const createAgent = async (data) => await createAgentAsync(data, dispatch);
    const getAgentDetails = async (data) => await getAgentAsync(data, dispatch);
    const updateAgent = async (data) => await updateAgentAsync(data, dispatch);
    const deleteAgent = async (data) => await deleteAgentAsync(data, dispatch);
    const getPartners = async (data) => await getPartnersAsync(data, dispatch);
    const getPartnerSubscriptions = async (data) => await getPartnerSubscriptionsAsync(data, dispatch);
    const getSoftwaresByService = async (data) => await getSoftwaresByServiceAsync(data, dispatch);
    const uploadAgentContractFile = async (data) => await uploadAgentContractFileAsync(data, dispatch);
    const updateAgentStatus = async (data) => await updateAgentStatusAsync(data, dispatch);
    const getAgentList = async (data) => await getAgentListAsync(data, dispatch);
    const values = { getSoftwaresByService, createAgent, deleteAgent, updateAgent, getPartners, getAgentDetails, getAgentList, getPartnerSubscriptions, uploadAgentContractFile, updateAgentStatus };
    return (<AgentContext.Provider value={values}>{children}</AgentContext.Provider>);
};
export default AgentContext;

import { combineReducers } from "redux";
import * as reducers from "./reducers";

const reducer = combineReducers({
  authUser:reducers.userReducer,
  globalState:reducers.globalReducer,
  profile:reducers.profileReducer,
});

export default reducer;

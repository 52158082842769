import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createServiceAsync, deleteServiceAsync, getServiceDetailsAsync, getSoftwareAsync, getServicesAsync, updateServiceAsync, getAddedServicesAsync, serviceStatusAsync } from "../api/services";

const ServicesContext = createContext();

export const ServicesProvider = ({ children }) => {
  const dispatch = useDispatch();

  const getServices = async (data) => await getServicesAsync(data, dispatch);

  const createService = async (data) => await createServiceAsync(data, dispatch);

  const getServiceDetails = async (data) => await getServiceDetailsAsync(data, dispatch);

  const updateService = async (data) => await updateServiceAsync(data, dispatch);

  const deleteService = async (data) => await deleteServiceAsync(data, dispatch);

  const getAddedServices = async (data) => await getAddedServicesAsync(data, dispatch);
  const getSoftware = async (data) => await getSoftwareAsync(data, dispatch);

  const serviceStatus = async (data) => await serviceStatusAsync(data, dispatch);

  const values = {
    getServices,
    createService,
    getServiceDetails,
    updateService,
    deleteService,
    getAddedServices,
    serviceStatus,
    getSoftware
  };

  return (
    <ServicesContext.Provider value={values}>
      {children}
    </ServicesContext.Provider>
  );
};

export default ServicesContext;

import APISources from "../sources"
import instance from "../utils/axios";


export const getProfileDetailsAsync = async (data, dispatch) => await instance.post(`${APISources().Profile.DetailsURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const updateProfileAsync = async (data, dispatch) => await instance.post(`${APISources().Profile.UpdateURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const uploadProfileImageAsync = async (data, dispatch) => await instance.post(`${APISources().Profile.ImageURI}/`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const deletedAcceptedFilesAsync = async (data, dispatch) => await instance.post(`${APISources().Profile.DeleteFilesURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })

export const getNotificationsAsync = async (data, dispatch) => await instance.get(`${APISources().Header.ListNotificationsURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getAllNotificationsAsync = async (data, dispatch) => await instance.post(`${APISources().Header.ListAllNotificationsURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const updateNotificationAsync = async (data, dispatch) => await instance.post(`${APISources().Header.UpadteNotificationURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const clearAllNotificationsAsync = async (data, dispatch) => await instance.post(`${APISources().Header.ClearAllNotificationsURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getConversationsAsync = async (data, dispatch) => await instance.get(`${APISources().Header.ListConversationsURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getAllConversationsAsync = async (data, dispatch) => await instance.post(`${APISources().Header.ListAllConversationsURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const updateConversationAsync = async (data, dispatch) => await instance.post(`${APISources().Header.UpadteConversationURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const clearAllConversationsAsync = async (data, dispatch) => await instance.post(`${APISources().Header.ClearAllConversationsURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
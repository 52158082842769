import React, { createContext, useEffect, useState } from "react";
import config from "../config";
import { USER_ACTION, USER_PERMISSIONS_ACTION } from "../store/actions";
import instance from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/loader";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const tokenVar = config?.tokenVar;
  const [loading, setLoading] = useState(false);

  const handleRedirect = (_) => {
    setLoading(false);
    if (
      window.location.pathname == config?.login_uri || window.location.pathname.includes("otpVerification") || window.location.pathname.includes("forgot-password") || loading ||config?.is_testing
    ) return;
    // sessionStorage.removeItem(tokenVar);
    localStorage.removeItem(btoa('jesi_holiday_popup_status'))
    window.location.replace(config?.login_uri);
  };

  const handleNext = (response) => {
    if (response?.status != 200) return handleRedirect();
    const permissions = {};
    response?.data?.user_permissions?.map((c_c) => {
      const key_ = c_c
        .replace("create", "")
        .replace("view", "")
        .replace("update", "")
        .replace("delete", "")
        .replace("seeAll", "");
      if (
        c_c.includes("view") ||
        c_c.includes("delete") ||
        c_c.includes("create") ||
        c_c.includes("update") ||
        c_c.includes("seeAll")
      ) {
        if (!permissions[key_]) permissions[key_] = {};
        if (c_c.includes("view"))
          permissions[key_]["view"] = c_c.includes("view");
        if (c_c.includes("delete"))
          permissions[key_]["delete"] = c_c.includes("delete");
        if (c_c.includes("create"))
          permissions[key_]["create"] = c_c.includes("create");
        if (c_c.includes("update"))
          permissions[key_]["update"] = c_c.includes("update");
        if (c_c.includes("seeAll"))
          permissions[key_]["list"] = c_c.includes("seeAll");
      }
    });
    dispatch({ type: USER_ACTION, payload: response?.data });
    dispatch({ type: USER_PERMISSIONS_ACTION, payload: permissions });
    if (response?.data?.email || response?.data?.id || response?.data?.role || response?.data?.name) setLoading(false)
  };


  const handleRefreshUser = async () => {
    await instance.get(`${config?.api_uri}/auth/whoami`).then((response) => handleNext(response?.data)).catch((_) => handleRedirect());
  }

  useEffect(async () => {
    const controller = new AbortController();
    setLoading(true);
    try {
      const accessToken = localStorage.getItem(tokenVar);
      if (accessToken || (accessToken && localStorage.getItem(btoa(config.remeber_me_token)))) {
        await instance.get(`${config?.api_uri}/auth/whoami`, { signal: controller.signal }).then((response) => handleNext(response?.data)).catch((_) => handleRedirect());
      } else {
        handleRedirect();
      }
    } catch (error) {
      console.error("error", error);
      handleRedirect();
    }
    return () => controller.abort();
  }, []);

  if (loading) return <Loader />

  return <AuthContext.Provider value={{ handleRefreshUser }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
import React, { createContext } from "react";
import { useDispatch } from "react-redux";
import {
  ListFilterBranchAsync,
  ListFilterRoleAsync,
  createUserAsync,
  deleteUserAsync,
  getUserBranchesAsync,
  getUserCustomersAsync,
  getUserDetailsAsync,
  getUserPartnersAsync,
  getUserRolesAsync,
  getUsersAsync,
  listFilterCustomersAsync,
  updateUserAsync,
  updateUserStatusAsync,
  getUserAgentsAsync
} from "../api/users";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const dispatch = useDispatch();
  const getUsers = async (data) => await getUsersAsync(data, dispatch);
  const createUser = async (data) => await createUserAsync(data, dispatch);
  const getUserDetails = async (data) => await getUserDetailsAsync(data, dispatch);
  const updateUser = async (data) => await updateUserAsync(data, dispatch);
  const deleteUser = async (data) => await deleteUserAsync(data, dispatch);

  const getUserCustomers = async (data) => await getUserCustomersAsync(data, dispatch);
  const getUserBranches = async (data) => await getUserBranchesAsync(data, dispatch);
  const getUserRoles = async (data) => await getUserRolesAsync(data, dispatch);
  const getUserPartners = async (data) => await getUserPartnersAsync(data, dispatch);
  const getUserAgents = async (data) => await getUserAgentsAsync(data,dispatch);
  const updateUserStatus = async (data) => await updateUserStatusAsync(data, dispatch);
  const ListFilterRole = async (data) => await ListFilterRoleAsync(data, dispatch);
  const listFilterCustomers = async (data) => await listFilterCustomersAsync(data, dispatch);
  const ListFilterBranch = async (data) => await ListFilterBranchAsync(data, dispatch);

  const values = {
    getUsers,
    createUser,
    getUserDetails,
    updateUser,
    deleteUser,
    getUserCustomers,
    getUserBranches,
    getUserRoles,
    getUserPartners,
    updateUserStatus,
    ListFilterRole,
    listFilterCustomers,
    ListFilterBranch,
    getUserAgents,
  };

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

export default UserContext;

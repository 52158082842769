import React from "react";
import * as apiUrls from "../lambdaApiUrls";
import instance from "../utils/axios";

export const apiCall = async (method, url, data, params = {}) => {
    const config = {
        ...params,
        signal: data?.signal,
        cancelToken: data?.cancelToken
    };
    return await instance[method](url, data, config);
};

export const getUrl = (endpoint, params) => apiCall("get", buildUrl(apiUrls[endpoint], params));
const buildUrl = (baseUrl, params) => {
    const queryString = new URLSearchParams(params).toString();
    return `${baseUrl}?${queryString}`;
};

import { ACCOUNT_INITIALIZE, USER_ACTION, USER_PERMISSIONS_ACTION } from "../actions";

const initialState = {
  user_permissions: {},
  user: {}
};

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_ACTION: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case USER_PERMISSIONS_ACTION: {
      return {
        ...state,
        user_permissions: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}

import React, { createContext } from "react";
import { useDispatch } from "react-redux";
import { getProjectDashboardAsync, getDashboardChartDetailsAsync, getAgentListAsync, getDashboardCustomersAsync, getDashboardBranchesAsync, getDashboardCountAsync, getDashboardNpsChartAsync, getDashboardInvoiceAsync, getDashboardTopCustomerAsync, getDashboardLatestReviewAsync, getDashboardInvoiceSummaryAsync, getTopFiveBillingsBAsync, getDashboardAsync, getStorageChartAsync, getProgressChartAsync, getUserServiceAsync, getDashboardPartnerAsync } from "../api/dashboard";

const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
    const dispatch = useDispatch();
    const getDashboardChartDetails = async (data) => await getDashboardChartDetailsAsync(data, dispatch);
    const getDashboardCustomers = async (data) => await getDashboardCustomersAsync(data, dispatch);
    const getDashboardBranches = async (data) => await getDashboardBranchesAsync(data, dispatch);
    const getDashboardCount = async (data) => await getDashboardCountAsync(data, dispatch);
    const getDashboardNps = async (data) => await getDashboardNpsChartAsync(data, dispatch);
    const getDashboardInvoice = async (data) => await getDashboardInvoiceAsync(data, dispatch);
    const getDashboardTopCustomer = async (data) => await getDashboardTopCustomerAsync(data, dispatch);
    const getDashboardTopBilling = async (data) => await getTopFiveBillingsBAsync(data, dispatch);
    const getDashboardLatestReview = async (data) => await getDashboardLatestReviewAsync(data, dispatch);
    const getDashboardInvoiceSummary = async (data) => await getDashboardInvoiceSummaryAsync(data, dispatch);
    const getDashboard = async (data) => await getDashboardAsync(data, dispatch);
    const getStorageChart = async (data) => await getStorageChartAsync(data, dispatch);
    const getProgressChart = async (data) => await getProgressChartAsync(data, dispatch);
    const getUserService = async (data) => await getUserServiceAsync(data, dispatch);
    const getDashboardPartner = async (data) => await getDashboardPartnerAsync(data, dispatch);
    const getAgentList = async (data) => await getAgentListAsync(data, dispatch);
    const getProjectDashboard = async (data) => await getProjectDashboardAsync(data, dispatch);

    const values = {
        getProjectDashboard,
        getDashboardChartDetails,
        getDashboardCustomers,
        getDashboardBranches,
        getDashboardCount,
        getDashboardNps,
        getDashboard,
        getAgentList,
        getUserService,
        getStorageChart,
        getProgressChart,
        getDashboardInvoice,
        getDashboardPartner,
        getDashboardTopCustomer,
        getDashboardTopBilling,
        getDashboardLatestReview,
        getDashboardInvoiceSummary,

    };

    return (<DashboardContext.Provider value={values}>{children}</DashboardContext.Provider>)
};

export default DashboardContext;
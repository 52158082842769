import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deletedAcceptedFilesAsync, clearAllConversationsAsync, clearAllNotificationsAsync, getAllConversationsAsync, getAllNotificationsAsync, getConversationsAsync, getNotificationsAsync, getProfileDetailsAsync, updateConversationAsync, updateNotificationAsync, updateProfileAsync, uploadProfileImageAsync } from "../api/profile";

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
    const dispatch = useDispatch();

    const getProfileDetails = async (data) => await getProfileDetailsAsync(data, dispatch);
    const updateProfile = async (data) => await updateProfileAsync(data, dispatch);
    const uploadProfileImage = async (data) => await uploadProfileImageAsync(data, dispatch);


    const getNotifications = async (data) => await getNotificationsAsync(data, dispatch);
    const getAllNotifications = async (data) => await getAllNotificationsAsync(data, dispatch);
    const updateNotification = async (data) => await updateNotificationAsync(data, dispatch);
    const clearAllNotifications = async (data) => await clearAllNotificationsAsync(data, dispatch);

    const getConversations = async (data) => await getConversationsAsync(data, dispatch);
    const getAllConversations = async (data) => await getAllConversationsAsync(data, dispatch);
    const updateConversation = async (data) => await updateConversationAsync(data, dispatch);
    const clearAllConversations = async (data) => await clearAllConversationsAsync(data, dispatch);
    const deletedAcceptedFiles = async (data) => await deletedAcceptedFilesAsync(data, dispatch);

    const values = {
        deletedAcceptedFiles,
        getProfileDetails,
        updateProfile,
        uploadProfileImage,
        getNotifications,
        getAllNotifications,
        updateNotification,
        clearAllNotifications,
        getConversations,
        getAllConversations,
        updateConversation,
        clearAllConversations
    };

    return (
        <ProfileContext.Provider value={values}>{children}</ProfileContext.Provider>
    );
};

export default ProfileContext;

import React, { createContext } from "react";
import { useDispatch } from "react-redux";
import {
  getNPSAsync, createNpsAsync, notNowNpsAsync, showPopupNpsAsync,
  getFilterBranchesAsync,
  getFilterCustomersAsync,
  getFilterUsersAsync
} from "../api/nps";

const NPSContext = createContext();

export const NPSProvider = ({ children }) => {
  const dispatch = useDispatch();
  const getNPS = async (data) => await getNPSAsync(data, dispatch);
  const createNPS = async (data) => await createNpsAsync(data, dispatch);
  const notNow = async (data) => await notNowNpsAsync(data, dispatch);
  const showPopup = async (data) => await showPopupNpsAsync(data, dispatch);
  const getFilterBranches = async (data) => await getFilterBranchesAsync(data, dispatch);
  const getFilterCustomers = async (data) => await getFilterCustomersAsync(data, dispatch);
  const getFilterUsers = async (data) => await getFilterUsersAsync(data, dispatch);

  const values = {
    getNPS,
    createNPS,
    notNow,
    showPopup,
    getFilterBranches,
    getFilterCustomers,
    getFilterUsers,
  };

  return <NPSContext.Provider value={values}>{children}</NPSContext.Provider>;
};

export default NPSContext;

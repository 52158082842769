import axios from "axios";
import config from "../config";

const instance = axios.create({
  baseURL: config.api_uri,
});


instance.interceptors.request.use(
  (config_) => {
    const token = sessionStorage.getItem(config?.tokenVar) || localStorage.getItem(config?.tokenVar)
    if (token) config_.headers.Authorization = token
    return config_
  },
  // (error) => {
  //   sessionStorage.removeItem(config?.tokenVar);
  //   window.location.replace(config?.login_uri);
  // }
);

// interceptor for http
instance.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.data) {
      return response;
    } else {
      return response;
    }
  },
  (error) => {
    return Promise.reject(axios.isCancel(error) ? "AxiosCancellation" : (error?.response?.status === 409 || error?.response?.status === 406) ? { status: error?.response?.status, data: error?.response?.data, statusText: error?.response.statusText } : error?.response?.data ? JSON.stringify(error?.response?.data) : "Something went wrong")
  }
);


export default instance;
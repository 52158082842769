import APISources from "../sources"
import instance from "../utils/axios";



export const getUsersAsync = async (data, dispatch) => await instance.post(`${APISources().Users.ListURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const createUserAsync = async (data, dispatch) => await instance.post(`${APISources().Users.CreateURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getUserDetailsAsync = async (data, dispatch) => await instance.post(`${APISources().Users.DetailsURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const updateUserAsync = async (data, dispatch) => await instance.post(`${APISources().Users.UpdateURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const deleteUserAsync = async (data, dispatch) => await instance.post(`${APISources().Users.DeleteURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getUserCustomersAsync = async (data, dispatch) => await instance.post(`${APISources().Users.UserCustomersURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getUserBranchesAsync = async (data, dispatch) => await instance.post(`${APISources().Users.UserBranchesURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getUserRolesAsync = async (data, dispatch) => await instance.post(`${APISources().Users.UserRolesURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getUserPartnersAsync = async (data, dispatch) => await instance.post(`${APISources().Users.UserPartnersURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getUserAgentsAsync = async (data, dispatch) => await instance.post(`${APISources().Users.UserAgentsURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const updateUserStatusAsync = async (data, dispatch) => await instance.post(`${APISources().Users.UserStatusURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const ListFilterRoleAsync = async (data, dispatch) => await instance.post(`${APISources().Users.ListFilterRoleURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const listFilterCustomersAsync = async (data, dispatch) => await instance.post(`${APISources().Users.listFilterCustomersURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const ListFilterBranchAsync = async (data, dispatch) => await instance.post(`${APISources().Users.ListFilterBranchURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
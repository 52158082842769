import React from 'react';

const NotFound = React.lazy(() => import("./views/notfound"));
const Dashboard = React.lazy(() => import("./views/dashboard"));
const CustomerDashboard = React.lazy(() => import("./views/dashboard/components/customerDashboard/customerDashboard"));
const ViewCustomer = React.lazy(() => import("./views/dashboard/components/customerDashboard/viewCustomer"));


//NPS
const PromotorScore = React.lazy(() => import('./views/nps'));
const NpsDetails = React.lazy(() => import('./views/nps/details'));
const CustomerReview = React.lazy(() => import('./views/dashboard/customerReview'));

// roles
const Roles = React.lazy(() => import('./views/roles'));
const EditRoles = React.lazy(() => import('./views/roles/editRole'));
const CreateRoles = React.lazy(() => import('./views/roles/createRole'));
// addedServices
const AddedServices = React.lazy(() => import('./views/added-services'));
const CreateAddedServices = React.lazy(() => import('./views/added-services/createAddedService'));
const EditAddedServices = React.lazy(() => import('./views/added-services/editAddedService'));
// partners
const Partners = React.lazy(() => import('./views/partners'));
const CreatePartner = React.lazy(() => import('./views/partners/createPartners'));
const EditPartner = React.lazy(() => import('./views/partners/editPartners'));
// users
const UsersWindow = React.lazy(() => import("./views/users"));
const CreateUser = React.lazy(() => import("./views/users/createUser"));
const EditUser = React.lazy(() => import("./views/users/editUser"));
// Branches
const Branches = React.lazy(() => import('./views/branches'));
const CreateBranch = React.lazy(() => import('./views/branches/createBranches'));
const EditBranch = React.lazy(() => import('./views/branches/editBranches'));
// Services
const ServiceManagement = React.lazy(() => import("./views/services"));
const CreateService = React.lazy(() => import("./views/services/createService"));
const EditService = React.lazy(() => import("./views/services/editService"));
// Profile
const Profile = React.lazy(() => import('./views/profile'));
// invoices
const InvoiceWindow = React.lazy(() => import("./views/invoice"));
const ViewInvoice = React.lazy(() => import("./views/invoice/customerInvoice"));
// Analytics
const Analytics = React.lazy(() => import('./views/analytics'));
// Customers
const CustomerManagement = React.lazy(() => import("./views/customers"));
const CreateCustomer = React.lazy(() => import("./views/customers/createCustomer"));
const EditCustomer = React.lazy(() => import("./views/customers/editCustomer"));
// Calendar
const DueDateCalendar = React.lazy(() => import("./views/calendar"))
// Projects
const Projects = React.lazy(() => import("./views/projects"));
const CreateProject = React.lazy(() => import("./views/projects/createProject"));
const EditProject = React.lazy(() => import("./views/projects/editProject"));
const ViewProject = React.lazy(() => import("./views/projects/viewProject"));
// Reports
const ReportManagement = React.lazy(() => import("./views/reports"));
const ProjectReports = React.lazy(() => import("./views/reports/projectReports"))
const ErrorReports = React.lazy(() => import("./views/reports/errorReports"))
const AddedProjectReports = React.lazy(() => import("./views/reports/addedProjectReports"))
const DelayedProjectReports = React.lazy(() => import("./views/reports/delayedProjectReports"))
// Storage
const StorageManagement = React.lazy(() => import('./views/storage'));
const FileDetails = React.lazy(() => import('./views/storage/viewDetails'));
// Holidays
const Holidays = React.lazy(() => import("./views/holidays"));
const CreateHoliday = React.lazy(() => import("./views/holidays/createHoliday"));
const EditHoliday = React.lazy(() => import("./views/holidays/editHoliday"));
// View all Notifications
const ViewallNotifications = React.lazy(() => import("./views/viewAllNotifications"));
// View all Comments
const ViewallComments = React.lazy(() => import("./views/viewAllComments"));

// Agents
const Agents = React.lazy(() => import("./views/Agents"));
const CreateAgent = React.lazy(() => import("./views/Agents/createAgent"));
const EditAgent = React.lazy(() => import("./views/Agents/editAgent"));

// Software
const Softwares = React.lazy(() => import("./views/software"));
const CreateSoftware = React.lazy(() => import("./views/software/createSoftware"));
const EditSoftware = React.lazy(() => import("./views/software/editSoftware"));

const routes = [
  { path: "/", exact: true, name: "Dashboard", element: Dashboard },
  // { path: "/customer-dashboard", name: "CustomerDashboard", element: CustomerDashboard },
  { path: "/customer-dashboard/:customerId", name: "CustomerDashboard", element: CustomerDashboard },
  { path: "/view-customer/:customerId", name: "ViewCustomer", element: ViewCustomer },

  //NPS
  { path: "/nps", name: "PromotorScore", element: PromotorScore },
  { path: "/nps/nps-details", name: "NpsDetils", element: NpsDetails },
  { path: "/review", name: "CustomerReview", element: CustomerReview },

  { path: '/profile', name: 'Profile', element: Profile },
  // roles
  { path: '/roles', name: 'Roles', element: Roles },
  { path: '/roles/create', name: 'CreateRoles', element: CreateRoles },
  { path: '/roles/edit/:role_id/', name: 'EditRoles', element: EditRoles },
  // addedServices
  { path: '/added-services', name: 'AddedServices', element: AddedServices },
  { path: '/added-services/create', name: 'CreateAddedServices', element: CreateAddedServices },
  { path: '/added-services/edit/:addon_id/', name: 'EditAddedServices', element: EditAddedServices },
  // partners
  { path: '/partners', name: 'Partners', element: Partners },
  { path: '/partners/create', name: 'CreatePartner', element: CreatePartner },
  { path: '/partners/edit/:partner_id/', name: 'EditPartner', element: EditPartner },
  // users
  { path: "/users", name: "UsersWindow", element: UsersWindow },
  { path: "/users/create", name: "CreateUser", element: CreateUser },
  { path: "/users/edit/:user_id/", name: "EditUser", element: EditUser },
  // branches
  { path: '/branches', name: 'Branches', element: Branches },
  { path: "/branches/create", name: "CreateBranch", element: CreateBranch },
  { path: "/branches/edit/:branch_id", name: "EditBranch", element: EditBranch },
  // services
  { path: "/services", name: "Services", element: ServiceManagement },
  { path: "/services/create", name: "CreateService", element: CreateService },
  { path: "/services/edit/:service_id/", name: "EditService", element: EditService },
  // profile
  { path: '/profile', name: 'Profile', element: Profile },
  // services
  { path: "/services", name: "Services", element: ServiceManagement },
  { path: "/services/create", name: "CreateService", element: CreateService },
  { path: "/services/edit/:service_id/", name: "EditService", element: EditService },
  // invoices
  { path: "/invoices", name: "InvoiceWindow", element: InvoiceWindow },
  { path: '/invoices/view/:customer_id', name: 'ViewInvoice', element: ViewInvoice },
  // Analytics
  { path: "/analytics", name: "Analytics", element: Analytics },
  // calendar
  { path: "/duedate-calendar", name: "Calendar", element: DueDateCalendar },
  // projects
  { path: "/projects", name: "Projects", element: Projects },
  { path: "/projects/create", name: "CreateProject", element: CreateProject },
  { path: "/projects/edit/:project_id/", name: "Edit Project", element: EditProject },
  { path: '/projects/view/:project_id/', name: 'View Project', element: ViewProject },
  // Customers
  { path: "/customers", name: "Customers", element: CustomerManagement },
  { path: "/customers/create", name: "CreateCustomer", element: CreateCustomer },
  { path: "/customers/edit/:customer_id/", name: "EditCustomer", element: EditCustomer },
  // Reports
  { path: "/reports", name: "Reports", element: ReportManagement },
  { path: "/reports/projectReports", name: "ProjectReports", element: ProjectReports },
  { path: "/reports/errorReports", name: "ErrorReports", element: ErrorReports },
  { path: "/reports/addedProjectReports", name: "AddedProjectReports", element: AddedProjectReports },
  { path: "/reports/delayedprojectreports", name: "DelayedProjectReports", element: DelayedProjectReports },
  // storage
  { path: "/storage", name: "StorageManagement", element: StorageManagement },
  { path: "/storage/file-details/:name", name: "FileDetails", element: FileDetails },

  // Holidays
  { path: "/holidays", name: "Customers", element: Holidays },
  { path: "/holidays/create", name: "CreateHoliday", element: CreateHoliday },
  { path: "/holidays/edit/:holiday_id/", name: "EditHoliday", element: EditHoliday },

  //NotFound
  { path: "/notfound", name: "NotFound", element: NotFound },
  // view all notifications 
  { path: "/notifications", name: "ViewallNotifications", element: ViewallNotifications },
  // view all comments 
  { path: "/comments", name: "ViewallComments", element: ViewallComments },

  // Agents
  { path: "/agents", name: "Agents", element: Agents },
  { path: "/agents/create", name: "CreateAgent", element: CreateAgent },
  { path: "/agents/edit/:agent_id/", name: "EditAgent", element: EditAgent },

  // Software
  { path: "/softwares", name: "Softwares", element: Softwares },
  { path: "/softwares/create", name: "CreateSoftware", element: CreateSoftware },
  { path: "/softwares/edit/:software_id/", name: "EditSoftware", element: EditSoftware },

];

export default routes;

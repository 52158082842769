import { CButton, CCard, CCardBody, CCardHeader, CCol, CForm, CFormCheck, CFormInput, CFormLabel, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import ArrowBack from '../../../assets/images/navbar/arrow-back.png';
import { Link } from 'react-router-dom';

export default function ResetPassword({ values, handleSubmit, errors, loading, handleChange }) {

    return (
        <div className="Container-fluid login">
            <div className='back-btn'><a href='/login'><span><img src={ArrowBack} /></span> Back</a></div>
            <div className='d-flex align-items-center justify-content-center next' style={{ padding: "0 300px" }}>
                <CCard>
                    <CCardHeader>Create New Password</CCardHeader>
                    <CCardBody className='py-4 px-5'>
                        <div className="logo-sec">
                            <p>Choose a strong password in combination with numbers, special characters</p>
                        </div>
                        <CForm onSubmit={e => e.preventDefault()}>
                            <div className="input-text">
                                <CFormLabel>New Password</CFormLabel>
                                <CFormInput type="password" className="" placeholder="Enter password" name="newpassword" id='newpassword' onChange={handleChange} value={values?.newpassword} />
                            </div>
                            {errors?.newpassword ?
                                <span style={{ color: 'red', fontSize: '12px' }}>{errors?.newpassword}</span>
                                : false}
                            <div className="input-text">
                                <CFormLabel>Confirm Password</CFormLabel>
                                <CFormInput type="password" className="" placeholder="Enter password" name="confirmpassword" id='confirmpassword' onChange={handleChange} value={values?.confirmpassword} />
                            </div>
                            {errors?.confirmpassword ?
                                <span style={{ color: 'red', fontSize: '12px' }}>{errors?.confirmpassword}</span>
                                : false}
                            {errors?.autherror ?
                                <span style={{ color: 'red', fontSize: '12px' }}>{errors?.autherror}</span>
                                : false}
                            <div className="buttons mt-5">
                                <CButton type="submit" onClick={handleSubmit}>{loading ? 'Please Wait...' : 'Submit'}</CButton>
                            </div>
                        </CForm>
                    </CCardBody>
                </CCard>
            </div>
        </div>
    )
}

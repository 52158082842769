import React, { createContext } from "react";
import { useDispatch } from "react-redux";

// project imports
import {
  authLoginAsync,
  authOtpAsync,
  authresendOtpAsync,
  forgotPasswordAsync,
  forgotCodeAsync,
  resetPasswordAsync
} from "../api/auth";
import config from "../config";

const UserAuthContext = createContext();

export const UserAuthProvider = ({ children }) => {
  const dispatch = useDispatch();


  const authLogout = () => {
    sessionStorage.removeItem(config?.tokenVar)
    localStorage.removeItem(config?.tokenVar)
    localStorage.removeItem(btoa(config.remeber_me_token))
    sessionStorage.removeItem("rateuspopup")
    localStorage.removeItem(btoa('jesi_holiday_popup_status'))
    window.location.replace(config?.login_uri)
  };


  const authLogin = async (data) => await authLoginAsync(data, dispatch);
  const otpLogin = async (data) => await authOtpAsync(data, dispatch);
  const resendOtp = async (data) => await authresendOtpAsync(data, dispatch);
  const forgotPassword = async (data) => await forgotPasswordAsync(data, dispatch);
  const codeCheck = async (data) => await forgotCodeAsync(data, dispatch);
  const resetPassword = async (data) => await resetPasswordAsync(data, dispatch);
  // const authLogout = async (data) =>  await authLogoutAsync(data, dispatch).catch(error => console.log("authLogoutAsync", error)).finally((_) => handleNext())

  const values = {
    authLogin,
    otpLogin,
    resendOtp,
    forgotPassword,
    codeCheck,
    resetPassword,
    authLogout
  }

  return (
    <UserAuthContext.Provider value={values}>
      {children}
    </UserAuthContext.Provider>
  );
};

export default UserAuthContext;

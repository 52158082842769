import APISources from "../sources"
import instance from "../utils/axios";

export const getHolidaysAsync = async (data, dispatch) => await instance.post(`${APISources().Holidays.ListURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const createHolidayAsync = async (data, dispatch) => await instance.post(`${APISources().Holidays.CreateURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getHolidayDetailsAsync = async (data, dispatch) => await instance.post(`${APISources().Holidays.DetailsURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const updateHolidayAsync = async (data, dispatch) => await instance.post(`${APISources().Holidays.UpdateURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const deleteHolidayAsync = async (data, dispatch) => await instance.post(`${APISources().Holidays.DeleteURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getHolidayInformationAsync = async (data, dispatch) => await instance.post(`${APISources().Holidays.HolidayInformation}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })


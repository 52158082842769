import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createAddonAsync,
  deleteAddonAsync,
  getAddonDetailsAsync,
  getAddonsAsync,
  updateAddonAsync,
} from "../api/addons";

const AddonContext = createContext();

export const AddonProvider = ({ children }) => {
  const dispatch = useDispatch();

  const getAddons = async (data) => await getAddonsAsync(data, dispatch);

  const createAddon = async (data) => await createAddonAsync(data, dispatch);

  const getAddonDetails = async (data) => await getAddonDetailsAsync(data, dispatch);

  const updateAddon = async (data) => await updateAddonAsync(data, dispatch);

  const deleteAddon = async (data) => await deleteAddonAsync(data, dispatch);

  const values = {
    getAddons,
    createAddon,
    getAddonDetails,
    updateAddon,
    deleteAddon,
  };

  return (
    <AddonContext.Provider value={values}>{children}</AddonContext.Provider>
  );
};

export default AddonContext;

import {
  CButton,
  CCard,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CRow
} from "@coreui/react";
import React, { useState } from "react";
import LoginBg from "../../../assets/images/login-bg.png";
import Logo from "../../../assets/images/logo.png";
import { LoadCanvasTemplate } from "react-simple-captcha";
import config from "../../../config";

export default function LoginForm({
  values,
  handleChange,
  errors,
  handleSubmit,
  loading,
  handleRemeberMe
}) {
  const [visible, setVisible] = useState(false);

  const HandleShow = (e) => {
    e.preventDefault();
    setVisible((prevState) => !prevState);
  };

  return (
    <>
      <div className="Container-fluid login">
        <CCard>
          <CRow>
            <CCol
              md={12}
              sm={12}
              lg={6}
              className="order-xs-2 order-sm-2 order-lg-1 left-form"
            >
              <div className="logo-sec">
                <img src={Logo} alt="Jesi-customer-portal" />
                <h2>Customer Portal</h2>
                <p>Login with your credentials</p>
              </div>
              <CForm onSubmit={(e) => e.preventDefault()}>
                <div className="input-text">
                  <CFormLabel>Email</CFormLabel>
                  <CFormInput
                    type="text"
                    className="text-transform-normal"
                    placeholder="Enter email id"
                    name="email"
                    id="email"
                    value={values?.email}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </div>
                {errors.email ? (
                  <div className="input-text">
                    <h6 style={{ color: "red", fontSize: "14px" }}>
                      {errors.email}
                    </h6>
                  </div>
                ) : (
                  false
                )}
                <div className="input-text">
                  <CFormLabel>Password</CFormLabel>
                  <CFormInput
                    type={!visible ? "password" : "text"}
                    className=""
                    placeholder="Enter password"
                    name="password"
                    id="password"
                    value={values?.password}
                    onChange={handleChange}
                    disabled={loading}
                  />
                  <i
                    onClick={HandleShow}
                    className={`fa ${!visible ? "fa-eye-slash" : "fa-eye"}`}
                  ></i>
                </div>
                {errors.password ? (
                  <div className="input-text">
                    <h6 style={{ color: "red", fontSize: "14px" }}>
                      {errors.password}
                    </h6>
                  </div>
                ) : (
                  false
                )}
                <div className="input-text">
                  <CFormLabel>Enter Captcha</CFormLabel>
                  <div className="captcha-sec">
                    <LoadCanvasTemplate
                      reloadText={"<i class='fa fa-refresh'></i>"}
                      reloadColor="#fff"
                    />
                    <CFormInput
                      type="text"
                      placeholder="Enter captcha text"
                      id="captcha_input"
                      disabled={loading}
                    />
                  </div>
                </div>
                {errors?.captcha ? (
                  <div className="input-text">
                    <h6 style={{ color: "red", fontSize: "14px" }}>
                      {errors?.captcha}
                    </h6>
                  </div>
                ) : (
                  false
                )}
                {errors.autherror ? (
                  <div className="input-text">
                    <h6 style={{ color: "red", fontSize: "14px" }}>
                      {errors.autherror}
                    </h6>
                  </div>
                ) : (
                  false
                )}
                <div className="buttons">
                  <CButton
                    type="submit"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? "Please Wait..." : "Login"}
                  </CButton>
                </div>
                <div className="forgot">
                  <CFormCheck label="Remember me" disabled={loading} checked={localStorage.getItem(btoa(config.remeber_me_token))} onClick={handleRemeberMe}></CFormCheck>
                  <a href="/forgot-password">Forgot password?</a>
                </div>
              </CForm>
            </CCol>
            <CCol
              md={12}
              sm={12}
              lg={6}
              className="order-xs-1 order-sm-1 order-lg-2 right-img"
            >
              <img src={LoginBg} />
            </CCol>
          </CRow>
        </CCard>
      </div>
    </>
  );
}

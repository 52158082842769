import React from 'react'
import {
    CButton,
    CCard,
    CCol,
    CForm,
    CFormCheck,
    CFormInput,
    CFormLabel,
    CRow,
    CModal,
    CModalBody,
    CModalHeader,
} from "@coreui/react";
import HolidayIcon from "../../assets/images/calendar.png";
import { useSelector } from 'react-redux';
import useHolidays from '../../hooks/useHolidays';

export default function HolidayNotification() {
    const { holiday_notification } = useSelector((state) => state.globalState);
    const { showHolidayNotification, closeHolidayNotification } = useHolidays();

    return (
        <CModal
            visible={holiday_notification?.open}
            onClose={closeHolidayNotification}
            alignment="center"
            className="delete-modal holiday"
        >
            <CRow>
                <CCol lg={2} md={3} className='d-md-flex d-none'>
                    <div className='left-icon'>
                        <img src={HolidayIcon} />
                    </div>
                </CCol>
                <CCol lg={10} md={9}>
                    <CModalHeader>
                        {holiday_notification?.header}
                    </CModalHeader>
                    <CModalBody>
                        <div className="delete-body">
                            <h2>
                                {holiday_notification?.title}</h2>
                            <p>
                                {holiday_notification?.body}
                            </p>
                        </div>
                    </CModalBody>
                </CCol>
            </CRow>
        </CModal>
    )
}

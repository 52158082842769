import APISources from "../sources"
import instance from "../utils/axios";
import { apiCall, getUrl } from "./apicall";

export const createSoftwareAsync = (data) => apiCall("post", `${APISources().Softwares.CreateURI}`, data);
export const getSoftwaresAsync = (data) => apiCall("post", `${APISources().Softwares.ListURI}`, data);
export const deleteSoftwareAsync = (data) => apiCall("post", `${APISources().Softwares.DeleteURI}/${data?.id}`, data);
export const getSoftwareDetailsAsync = async (data, dispatch) => await instance.get(`${APISources().Softwares.DetailsURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const updateSoftwareAsync = (data) => apiCall("post", `${APISources().Softwares.UpdateURI}/${data?.id}`, data);
export const fetchAllSoftwaresAsync = (data) => apiCall("get", `${APISources().Softwares.AllListURI}`, data);


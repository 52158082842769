import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CContainer,
  CHeader,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CDropdownMenu,
  CDropdownItem,
  CButton,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu, cilSearch } from "@coreui/icons";
import ProfileDropdown from "../../components/profileDropdown";
import Logo2 from "../../assets/images/logo.png";
import { HOLIDAY_NOTIFICATIONS_ACTION, SIDEBAR_ACTION } from "../../store/actions";
import NotificationsDropdown from "../components/notifications";
import CommentsDropdown from "../components/comments";
import GlobalSearch from "./globalSearch";
import HolidayNotification from "../../components/holidayNotification";
import useHolidays from "../../hooks/useHolidays";
import useProfile from "../../hooks/useProfile";

export default function Header() {
  const dispatch = useDispatch();
  const { unfoldable } = useSelector((state) => state.globalState);
  const { getHolidayInformation } = useHolidays();
  const { getConversations, getNotifications } = useProfile();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [chatCount, setChatCount] = useState("");
  const [notificationData, setNotificationData] = useState([]);
  const [notificationList,setNotificationList] = useState([]);
  const [conversationList,setConversationList] = useState([]);
  const [notifiCount, setNotifiCount] = useState("");

  const handleFetchNotificationData = async () => {
    setLoading(true);
    await getNotifications().then((response) => {
      setNotificationData(response?.data?.data)
      setNotificationList(response?.data?.notification_ids)
      setNotifiCount(response?.data)
    }).catch((error) => console.log("getNotificationsAsync", error)).finally((_) => setLoading(false));
  };

  const handleFetchData = async () => {
    setLoading(true);
    await getConversations().then((response) => {
      setData(response?.data?.data)
      setConversationList(response?.data?.conversation_ids)
      setChatCount(response?.data?.chat_count)
    }).catch((error) => console.log("getConversations", error)).finally((_) => setLoading(false));
  };

  useEffect(() => {
    handleFetchData();
    handleFetchNotificationData()
  }, []);


  const handleSidebar = () => {
    dispatch({
      type: SIDEBAR_ACTION,
      payload: !unfoldable,
    });
  };


  const showHolidayNotification = (response) => {
    if (response?.status != 200 || localStorage.getItem(btoa('jesi_holiday_popup_status'))) return
    dispatch({
      type: HOLIDAY_NOTIFICATIONS_ACTION,
      payload: {
        open: true,
        header: 'Holiday Reminder',
        title: response?.data?.holiday_name || '',
        body: response?.data?.message || '',
      },
    });
  }

  useEffect(() => {
    getHolidayInformation().then((response) => showHolidayNotification(response?.data)).catch((error) => console.log('getHolidayInformation', error))
  }, [])


  return (
    <CHeader position="sticky">
      <CContainer fluid className="mx-1">
        <CHeaderToggler className="ps-1" onClick={handleSidebar}>
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderNav className="logo-nav">
          <CNavItem>
            <CNavLink href="/" className="px-0">
              <img src={Logo2} />
              <p className="version">v2.0.0</p>
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="search-bar d-md-flex mx-auto">
          <CNavItem>
            <GlobalSearch />
          </CNavItem>
        </CHeaderNav>

        <CHeaderNav onClick={handleFetchData}>
          <CNavItem>
            <CommentsDropdown data={data} conversationList={conversationList} loading={loading} setLoading={setLoading} chatCount={chatCount} handleFetchData={handleFetchData} />
          </CNavItem>
        </CHeaderNav>

        <CHeaderNav onClick={handleFetchNotificationData}>
          <CNavItem>
            <NotificationsDropdown data={notificationData} notificationList={notificationList} loading={loading} setLoading={setLoading} notifiCount={notifiCount} handleFetchData={handleFetchNotificationData} />
          </CNavItem>
        </CHeaderNav>
        
        <CHeaderNav className="profile-nav">
          <ProfileDropdown />
        </CHeaderNav>
      </CContainer>
      <HolidayNotification />
    </CHeader >
  );
};


{/* <div className="delete-body">
<h2>Pooja Holidays</h2>
<p>There's national holiday coming up in India on 23-Oct-24 , which might  affect your project delivery dates. To make sure everything runs smoothly, could you send in your projects a bit earlier than usual? This wil help us avoid
    any potential delays and ensure on-time delivery. We really appreciate your support and understanding. if you have any questions or concerns about your projects or our delivery timeline, feel free to reach out to ibrahim@jerseyeng.com. 
    <br/>
    <br/>
    Rest assured, We're dedicated to <span>Helping you to be your BEST!</span>
    <br/>
    <br/>
    Thank you, <br/>
    <span>Team Jesi</span>
</p>
</div> */}
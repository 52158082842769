// import React from "react";
import { UserAuthProvider } from "./userAuthContext";
import { UserProvider } from "./userContext";
import { RolesProvider } from "./rolesContext";
import { AddonProvider } from "./addonContext";
import { PartnerProvider } from "./partnerContext";
import { ServicesProvider } from "./serviceContext";
import { BranchProvider } from "./branchContext";
import { AuthProvider } from "./authContext";
import { ProfileProvider } from "./profileContext";
import { InvoiceProvider } from "./invoiceContext";
import { AnalyticsProvider } from "./analyticsContext";
import { CustomersProvider } from "./customerContext";
import { ProjectsProvider } from "./projectsContext";
import { PermissionsProvider } from "./userPermissionContext";
import { ReportsProvider } from "./reportsContext";
import { CalendarProvider } from "./calendarContext";
import { HolidaysProvider } from "./holidaysContext";
import { StorageProvider } from "./storageContext";
import { NPSProvider } from "./npsContext";
import { DashboardProvider } from "./dashboardContext";
import { AgentProvider } from "./agentContext";
import { SoftwareProvider } from "./softwareContext";


const AppContextProvider = ({ children }) => {
  return (
    <UserAuthProvider>
      <AuthProvider>
        <PermissionsProvider>
          <UserProvider>
            <InvoiceProvider>
              <AnalyticsProvider>
                <ProfileProvider>
                  <ServicesProvider>
                    <SoftwareProvider>
                      <RolesProvider>
                        <AddonProvider>
                          <AgentProvider>
                            <PartnerProvider>
                              <BranchProvider>
                                <ProjectsProvider>
                                  <CustomersProvider>
                                    <ReportsProvider>
                                      <HolidaysProvider>
                                        <NPSProvider>
                                          <DashboardProvider>
                                            <StorageProvider>
                                              <CalendarProvider>
                                                {children}
                                              </CalendarProvider>
                                            </StorageProvider>
                                          </DashboardProvider>
                                        </NPSProvider>
                                      </HolidaysProvider>
                                    </ReportsProvider>
                                  </CustomersProvider>
                                </ProjectsProvider>
                              </BranchProvider>
                            </PartnerProvider>
                          </AgentProvider>
                        </AddonProvider>
                      </RolesProvider>
                    </SoftwareProvider>
                  </ServicesProvider>
                </ProfileProvider>
              </AnalyticsProvider>
            </InvoiceProvider>
          </UserProvider>
        </PermissionsProvider>
      </AuthProvider>
    </UserAuthProvider>
  );
};

export default AppContextProvider;

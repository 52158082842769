import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadInvoiceAsync, getInvoicesAsync, sendInvoiceAsync, getInvoicesViewAsync, listFilterCustomersURIAsync, listFilterMonthYearURIAsync, listPartnerToCustomerFiltersAsync } from "../api/invoices";

const InvoiceContext = createContext();

export const InvoiceProvider = ({ children }) => {
    const dispatch = useDispatch();

    const getInvoices = async (data) => await getInvoicesAsync(data, dispatch);
    const getViewInvoices = async (data) => await getInvoicesViewAsync(data, dispatch);
    const downloadInvoice = async (data) => await downloadInvoiceAsync(data, dispatch);
    const sendInvoice = async (data) => await sendInvoiceAsync(data, dispatch);
    const listFilterCustomersURI = async (data) => await listFilterCustomersURIAsync(data, dispatch);
    const listFilterMonthYearURI = async (data) => await listFilterMonthYearURIAsync(data, dispatch);
    const listPartnerToCustomerFilters = async (data) => await listPartnerToCustomerFiltersAsync(data, dispatch);

    const values = {
        getInvoices,
        getViewInvoices,
        downloadInvoice,
        sendInvoice,
        listFilterCustomersURI,
        listFilterMonthYearURI,
        listPartnerToCustomerFilters
    };

    return (
        <InvoiceContext.Provider value={values}>{children}</InvoiceContext.Provider>
    );
};

export default InvoiceContext;

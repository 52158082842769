import APISources from "../sources"
import instance from "../utils/axios";


export const getNPSAsync = async (data, dispatch) => await instance.post(`${APISources().NPS.ListURL}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const createNpsAsync = async (data, dispatch) => await instance.post(`${APISources().NPS.CreateURL}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const notNowNpsAsync = async (data, dispatch) => await instance.post(`${APISources().NPS.NotNowURL}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const showPopupNpsAsync = async (data, dispatch) => await instance.post(`${APISources().NPS.ShowPopup}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getFilterBranchesAsync = async (data, dispatch) => await instance.post(`${APISources().NPS.ListFilterBranchesURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getFilterCustomersAsync = async (data, dispatch) => await instance.post(`${APISources().NPS.ListFilterCustomersURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getFilterUsersAsync = async (data, dispatch) => await instance.post(`${APISources().NPS.ListFilterUsersURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })

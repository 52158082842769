import { COMMENTS_ACTION, NOTIFICATIONS_ACTION } from "../actions";


const initialState = {
    notifications: [],
    comments: [],
};

export function profileReducer(state = initialState, action) {
    switch (action.type) {
        case NOTIFICATIONS_ACTION: {
            return {
                ...state,
                notifications: action.payload,
            };
        }
        case COMMENTS_ACTION: {
            return {
                ...state,
                comments: action.payload,
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};
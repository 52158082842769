import APISources from "../sources"
import instance from "../utils/axios";
import * as apiUrls from "../lambdaApiUrls";

export const getReportsAsync = async (data, dispatch) => await instance.post(`${APISources().Reports.ListURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getProjectReportsAsync = async (data, dispatch) => await instance.post(`${APISources().Reports.ReportsURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })

export const getErrorReportsAsync = async (data, dispatch) => await instance.post(`${APISources().Reports.ErrorReportingURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const downloadErrorReportAsync = async (data, dispatch) => await instance.post(`${APISources().Reports.DownloadErrorReportURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })

export const exportPDFProjectsListAsync = async (data, dispatch) => await instance.post(`${APISources().Reports.ExportProjectPDFURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken, responseType: 'blob' })
export const exportCSVProjectsListAsync = async (data, dispatch) => await instance.post(`${APISources().Reports.ExportCSVURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getAddedProjectReportsAsync = async (data, dispatch) => await instance.post(`${APISources().Reports.AddedReportsURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const exportPDFAddedProjectsListAsync = async (data, dispatch) => await instance.post(`${APISources().Reports.ExportAddedPDFURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken, responseType: 'blob' })
export const exportCSVAddedProjectsListAsync = async (data, dispatch) => await instance.post(`${APISources().Reports.ExportAddedCSVURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const exportDelayedProjectsListAsync = async (data, dispatch) => await instance.post(`${APISources().Reports.ExportDelayedReportsURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })

export const ListFilterPartnerAsync = async (data, dispatch) => await instance.post(`${APISources().Reports.ListFilterPartnerURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })

export const listFilterCustomersAsync = async (data, dispatch) => await instance.post(`${APISources().Reports.listFilterCustomersURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })

export const ListFilterBranchAsync = async (data, dispatch) => await instance.post(`${APISources().Reports.ListFilterBranchURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getDelayedProjectReportsAsync = async (data, dispatch) => await instance.post(`${APISources().Reports.DelayedProjectReportsURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getServiceAsync = async (data, dispatch) => await instance.post(`${APISources().Reports.ServiceURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getAgentListAsync = async (data, dispatch) => await instance.get(`${apiUrls?.agentUrl}?partner=${data?.partner}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
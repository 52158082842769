import APISources from "../sources"
import instance from "../utils/axios";
import * as apiUrls from "../lambdaApiUrls";
import { apiCall, getUrl } from "./apicall";
import { useDispatch } from "react-redux";

// export const getProjectsAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.ListURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getProjectsAsync = async (data, dispatch) => await instance.post(`${apiUrls?.projectListUrl}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const createProjectAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.CreateURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getProjectDetailsAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.DetailsURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const updateProjectAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.UpdateURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const deleteProjectAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.DeleteURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getProjectDetailedViewAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.DetailedURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getProjectConversationsAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.ConversationsURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const createProjectConversationAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.ConversationURI}/${data?.project_id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getProjectActivitiesAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.ActivitiesURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const editProjectCommentAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.EditConversationURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const deleteProjectCommentAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.DeleteConversationURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const exportPDFProjectsListAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.ExportPDFURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const exportProjectPDFAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.ExportProjectPDFURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const exportCSVProjectsListAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.ExportCSVURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getProjectUsersAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.ListUsersURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getProjectCustomerBranchesAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.ListBranchesURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getProjectServicesAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.ListServicesURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getProjectAddedServicesAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.ListAddedServicesURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const UploadProjectFileAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.UploadFileURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const ProjectAcceptFileAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.ProjectAcceptURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const SendErrorRequestFileAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.SendErrorRequestURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const globaleSearchAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.GlobalSearchURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const checkStatusAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.CheckStatusURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const projectReopenAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.ProjectReopenURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const deleteProjectAttachmentAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.DeleteAttachment}/${data?.id}`, { signal: data?.signal, cancelToken: data?.cancelToken })
export const listFilterCustomersAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.listFilterCustomersURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const listFilterPartnersAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.listFilterPartnersURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const listFilterBranchesAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.ListFilterBranchesURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const createProjectAdendumAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.CreateAdendumURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const removeFileAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.RemoveFileURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const projectCancellationAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.ProjectCancellationURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const projectCancelRejectAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.ProjectCancelRejectURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const downloadFileAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.DownloadURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const GetScopeOfWorkAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.GetScopeOfWorkURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const GetDemoProjectsCountAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.GetDemoProjectsURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const RfiProjectsAsync = async (data, dispatch) => await instance.post(`${APISources().Projects.RfiProjectsURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const GetRfiCommentsAsync = async (data, useDispatch) => await instance.post(`${APISources().Projects.GetRfiCommentsURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const FetchLeaveAsync = async (data, useDispatch) => await instance.post(`${APISources().Projects.FetchLeaveURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getDueRequestAsync = async (data, useDispatch) => await instance.post(`${APISources().Projects.DuerequestdetailsURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const ApproveDueDateURIAsync = async (data, useDispatch) => await instance.post(`${APISources().Projects.ApproveduedateURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const RejectDueDateURIAsync = async (data, useDispatch) => await instance.post(`${APISources().Projects.RejectduedateURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })

export const getAgentListAsync = (data) => getUrl("agentUrl", data)
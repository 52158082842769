import React, { useEffect, useState } from 'react';
import ForgotPassword from './forgotPassword';
import useAuth from "../../../hooks/useAuth";
import usePermissions from '../../../hooks/usePermissions';
import { useNavigate } from 'react-router-dom';

export default function ForgotPasswordIndex() {
  const { checkPermission, sendNotification } = usePermissions();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({
    email: ''
  })
  const { forgotPassword } = useAuth();

  const handleChange = (e) => {
    setErrors({})
    const values_ = { ...values }
    values_[e.target.id] = e.target.value
    setValues(values_)
  }

  const handleNext = (response) => {
    navigate(`/forgot-password/verify-email/${btoa(values?.email)}/${response?.customer_id}`)
  }

  const handleResponse = (response) => {
    if (response.status != 200) return sendNotification({ body: `Failed to sent verification code ${response?.messages}`, severity: 'error' })
    handleNext(response)
    return sendNotification({ body: `Verfication code has been sent to ${values?.email} successfully`, severity: 'success' })
  };


  const handleErrorNext = (error) => {
    
    return sendNotification({ body: `Failed to sent verification code`, severity: 'error' })
    
  };


  const handleSubmit = async (e) => {
    e.preventDefault()
    const errors_ = {}
    errors_['email'] = !values?.email ? "Email is required" : ''
    setErrors(errors_)
    if (Object.values(errors_).find(_ => _)) return
    setLoading(true)
    const email = btoa(values?.email)
    await forgotPassword({ email: email }).then(response => handleResponse(response?.data)).catch(error => handleErrorNext(error)).finally(_ => setLoading(false))
  }

  return <ForgotPassword values={values} loading={loading} handleChange={handleChange} errors={errors} handleSubmit={handleSubmit} />

}
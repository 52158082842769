import React, { useEffect, useRef, useState } from 'react';
import Emailverification from './emailverification';
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useParams } from 'react-router-dom';
import usePermissions from '../../../hooks/usePermissions';

export default function EmailverificationIndex() {
    const { checkPermission, sendNotification } = usePermissions();
    const navigate = useNavigate();
   
    const { email, customerid} = useParams();
    const { codeCheck, resendOtp } = useAuth();
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const [time, setTime] = useState({ minutes: 1, seconds: 30 });
    const digit1Ref = useRef(null); const digit2Ref = useRef(null); const digit3Ref = useRef(null); const digit4Ref = useRef(null)
    const [allDigitsFilled, setAllDigitsFilled] = useState(false);

    const [values, setValues] = useState({
        digit1: '',
        digit2: '',
        digit3: '',
        digit4: ''
    })

    useEffect(() => {
        digit1Ref?.current?.focus()
    }, [, email])


    useEffect(() => {
        const interval = setInterval(() => {
            if (time?.seconds > 0) setTime(prevTime => ({ ...prevTime, seconds: prevTime?.seconds - 1 }))
            if (time?.seconds === 0) {
                if (time?.minutes === 0) clearInterval(interval);
                else setTime(prevTime => ({ ...prevTime, seconds: 59, minutes: prevTime?.minutes - 1 }))
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [time]);

    useEffect(() => {
        setAllDigitsFilled(
            values?.digit1 && values?.digit2 && values?.digit3 && values?.digit4
        );
    }, [values])

    const handleChange = (e) => {
        setErrors({})
        const values_ = { ...values }
        values_[e.target.id] = e.target.value
        setValues(values_)
        switch (e.target.id) {
            case 'digit1': return digit2Ref.current.focus()
            case 'digit2': return digit3Ref.current.focus()
            case 'digit3': return digit4Ref.current.focus()
        }
    }

    const handleSetDefault = () => {
        setValues({ ...values, digit1: '', digit2: '', digit3: '', digit4: '' })
    }

    const handleClearGoBack = () => {
        handleSetDefault()
    }

    const handleResendVerificationCode = async (e) => {
        setTime({ minutes: 1, seconds: 30 })
        handleSetDefault()
        setLoading(true)
        const data = { email: email, customerid: customerid }
        await resendOtp({ email: email, customerid: customerid }).then(response => handleResendResponse(response?.data)).catch(_ => handleResendErrorNext()).finally(_ => setLoading(false))
    }

    const handleResendResponse = (response) => {
        if (response.status != 200) return sendNotification({ body: `Failed to re resend verification code, ${response?.messages}`, severity: 'error' })
        return sendNotification({ body: `Verification code has been sent`, severity: 'success' })
    };

    const handleResendErrorNext = (error) => {
        return sendNotification({ body: `Failed to re resend verification code`, severity: 'error' })
    };


    const handleNext = () => {
        navigate(`/forgot-password/reset-password/${(email)}`)
    }

    const handleResponse = (response) => {
      if(response.status != 200) return sendNotification({ body: `Failed to verify the Email, ${response?.messages}`, severity: 'error' })
      handleNext()
      return sendNotification({ body: `Email verified successfully`, severity: 'success'})
    };
  
    const handleErrorNext = (error) => {
      return sendNotification({ body: `Failed to verify the Email`, severity: 'error' })
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        handleSetDefault()
        const errors_ = {}
        errors_['verification_code'] = !values?.digit1 || !values?.digit2 || !values?.digit3 || !values?.digit4 ? 'Verification code is required' : false
        setErrors(errors_)
        if (Object.values(errors_).find(_ => _)) return
        setLoading(true)
        const data = { email: email, customerid: customerid, code: btoa(`${values?.digit1}${values?.digit2}${values?.digit3}${values?.digit4}`) }
        await codeCheck({ data }).then(response => handleResponse(response?.data)).catch(_ => handleErrorNext()).finally(_ => setLoading(false))
    }

    return <Emailverification values={values} loading={loading} handleSubmit={handleSubmit} handleChange={handleChange} time={time} handleResendVerificationCode={handleResendVerificationCode} digit1Ref={digit1Ref} digit2Ref={digit2Ref} digit3Ref={digit3Ref} digit4Ref={digit4Ref} handleClearGoBack={handleClearGoBack} errors={errors} allDigitsFilled={allDigitsFilled} email={email}/>
}
import APISources from "../sources"
import instance from "../utils/axios";


// Auth
export const authLoginAsync = async (data, dispatch) => {
    return await instance.post(`${APISources().Authentication.LoginURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
}

//OTP
export const authOtpAsync = async (data, dispatch) => {
    return await instance.post(`${APISources().Authentication.OtpURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
}

//RESEND OTP
export const authresendOtpAsync = async (data, dispatch) => {
    return await instance.post(`${APISources().Authentication.ResendURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
}

export const authLogoutAsync = async (data, dispatch) => {
    return await instance.post(`${APISources().Authentication.LogoutURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
}

//FORGOT PASSWORD
export const forgotPasswordAsync = async (data, dispatch) => {
    return await instance.post(`${APISources().Authentication.ForgotURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
}

//CODE FOR FORGOT PASSWORD
export const forgotCodeAsync = async (data, dispatch) => {
    return await instance.post(`${APISources().Authentication.ForgotCodeURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
}

//RESET PASSWORD
export const resetPasswordAsync = async (data, dispatch) => {
    return await instance.post(`${APISources().Authentication.ResetPasswordURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
}
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { CButton, CSidebar, CSidebarBrand, CSidebarNav } from "@coreui/react";
import { SidebarNav } from "./sidebarNav";
import Logo from "../assets/images/logo-white.png";
import Logo2 from "../assets/images/logo.png";
import ProfileDropdown from "../components/profileDropdown";
import { SIDEBAR_ACTION } from "../store/actions";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const dispatch = useDispatch();
  const [{ unfoldable, navigations }] = useSelector((state) => [
    state.globalState,
  ]);
  const [{user}] = useSelector(state=>[state.authUser])
 
  const handleSidebar = () => {
    dispatch({
      type: SIDEBAR_ACTION,
      payload: !unfoldable,
    });
  };

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      className={`d-md-flex ${unfoldable ? "" : "d-none"}`}
    >
      <CSidebarBrand to="/">
        <Link to="/">
        <img src={Logo} className="lg-logo" />
        <img src={Logo2} className="sm-logo" />
        <p className="version">{user?.version}</p>
        </Link>
        <CButton className="close-btn" onClick={handleSidebar}>
          <i className="fa fa-times"></i>
        </CButton>
      </CSidebarBrand>
      <CSidebarBrand className="side-profile">
        <ProfileDropdown />
      </CSidebarBrand>
      <CSidebarNav
        className="responsive-sidebar d-none"
        onClick={handleSidebar}
      >
        <SidebarNav items={navigations} />
      </CSidebarNav>
      <CSidebarNav className="d-none d-md-flex">
        <SidebarNav items={navigations} />
      </CSidebarNav>
    </CSidebar>
  );
};

export default Sidebar;

import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAnalyticsAsync, getAnalyticsAgentListAsync, getAnalyticsCustomersAsync, getFTEAnalyticsAsync, getQTOAnalyticsAsync, getAnalyticsPartnerAsync } from "../api/analytics";

const AnalyticsContext = createContext();

export const AnalyticsProvider = ({ children }) => {
    const dispatch = useDispatch();

    const getAnalytics = async (data) => await getAnalyticsAsync(data, dispatch);
    const getQTOAnalytics = async (data) => await getQTOAnalyticsAsync(data, dispatch);
    const getFTEAnalytics = async (data) => await getFTEAnalyticsAsync(data, dispatch);
    const getAnalyticsCustomers = async (data) => await getAnalyticsCustomersAsync(data, dispatch);
    const getAnalyticsPartner = async (data) => await getAnalyticsPartnerAsync(data, dispatch);
    const getAnalyticsAgent = async (data) => await getAnalyticsAgentListAsync(data, dispatch);

    const values = {
        getAnalyticsAgent,
        getAnalytics,
        getAnalyticsCustomers,
        getQTOAnalytics,
        getFTEAnalytics,
        getAnalyticsPartner,
    };

    return (
        <AnalyticsContext.Provider value={values}>{children}</AnalyticsContext.Provider>
    );
};

export default AnalyticsContext;

import { useEffect, useState } from 'react'
import axios from 'axios'
import { getProjectsAsync, globaleSearchAsync } from '../../api/projects'

export default function useGlobalSearchListing(pageNumber, query) {
    const [busy, setBusy] = useState(true)
    const [searchData, setSearchData] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const controler = new AbortController()


    const handleFetchData = async () => {
        if (!pageNumber || !query) return
        setBusy(true)
        await getProjectsAsync({
            search: query,
            customer: '',
            branch: '',
            status: '',
            pageNumber: pageNumber,
            filter_start_date: '',
            filter_end_date: '',
            signal: controler.signal,
          }).then(response => {
            setSearchData(c => [...c, ...response?.data?.data])
            setHasMore(response?.data?.hasMore)
        }).catch(e => {
            if (axios.isCancel(e)) return
        }).finally((_) => setBusy(false))
    }

    const handleFetch = () => {
        setSearchData([])
        handleFetchData()
    }


    useEffect(() => {
        handleFetchData()
        return _ => controler.abort()
    }, [pageNumber])


    useEffect(() => {
        if(!query) return
        setSearchData([])
        handleFetchData()
        return _ => controler.abort()
    }, [query])


    return { busy, setBusy, searchData, hasMore, handleFetch, setSearchData }
}
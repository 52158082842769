import React, { useEffect, useRef, useState } from 'react';
import ResetPassword from "./resetPassword";
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from "../../../hooks/useAuth";
import { ValidatePassword } from '../../../utils/formaters';
import usePermissions from '../../../hooks/usePermissions';

export default function ResetPasswordIndex() {
    const { checkPermission, sendNotification } = usePermissions();
    const navigate = useNavigate();
    const { email } = useParams();
    const { resetPassword } = useAuth();
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState({
        newpassword: '',
        confirmpassword: ''
    })

    const handleChange = (e) => {
        setErrors({})
        const values_ = { ...values }
        values_[e.target.id] = e.target.value
        setValues(values_)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors_ = {}
        errors_["newpassword"] = !values?.newpassword ? "This field is required" : !ValidatePassword(values?.newpassword) ? "Password should be at least 8 characters in length and should include at least one upper case letter, one number, and one special character" : "";
        errors_["confirmpassword"] = ValidatePassword(values?.newpassword) && !values?.confirmpassword ? "This field is required" : ValidatePassword(values?.newpassword) && values?.newpassword != values?.confirmpassword ? "Password doesn't match" : "";
        setErrors(errors_)
        if (Object.values(errors_).find(_ => _)) return
        setLoading(true)
        const password = btoa(values?.newpassword)
        const confirmpassword = btoa(values?.confirmpassword)
        await resetPassword({ email: email, password: password, confirmpassword: confirmpassword }).then(response => handleResponse(response?.data)).catch(_ => handleErrorNext()).finally(_ => setLoading(false))
    }

    const handleNext = () => {
        navigate(`/login`)
    }

    const handleResponse = (response) => {
        if (response.status != 200) return sendNotification({ body: `Failed change password, ${response?.messages}`, severity: 'error' })
        handleNext()
        return sendNotification({ body: `Password changed successfully`, severity: 'success' })
    };

    const handleErrorNext = (error) => {
        return sendNotification({ body: `Failed change password`, severity: 'error' })
    };


    return <ResetPassword values={values} loading={loading} errors={errors} handleSubmit={handleSubmit} handleChange={handleChange} email={email} />

}
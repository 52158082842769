// USER
export const USER_PERMISSIONS_ACTION = "USER_PERMISSIONS_ACTION";
export const USER_ACTION = "USER_ACTION";
export const ACCOUNT_INITIALIZE = "ACCOUNT_INITIALIZE";
// SIDEBAR
export const SIDEBAR_ACTION = "SIDEBAR_ACTION";
export const SIDEBAR_NAVIGATION_ITEMS = "SIDEBAR_NAVIGATION_ITEMS";
export const USER_NOTIFICATION = "USER_NOTIFICATION";
export const GLOBAL_SEARCH_QUERY = "GLOBAL_SEARCH_QUERY";
// PROFILE
export const NOTIFICATIONS_ACTION = "NOTIFICATIONS_ACTION";
export const HOLIDAY_NOTIFICATIONS_ACTION = "HOLIDAY_NOTIFICATIONS_ACTION";
export const COMMENTS_ACTION = "COMMENTS_ACTION";
export const FILTER_ARGS_ACTION = "FILTER_ARGS_ACTION";

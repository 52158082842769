import { FormatMinDate } from "./formaters";


export const isDateDisabled = (date, datas) => {

    if (!Array.isArray(datas)) return false;

    const isSunday = date.getDay() === 0;
    const normalizeDateToLocal = (date) => {
        return FormatMinDate(date);
    };

    const normalizedDate = normalizeDateToLocal(date);
    const today = normalizeDateToLocal(new Date());

    const isPastDate = normalizedDate < today;

    const isHoliday = datas?.some(holiday => {
        const holidayFrom = holiday?.holiday_from;
        const holidayTo = holiday?.holiday_to;
        return normalizedDate >= holidayFrom && normalizedDate <= holidayTo;
    });

    return isSunday || isHoliday || isPastDate;
};
import React, { useEffect, useRef, useState } from 'react'
import OtpVerification from "./otpVerification";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useParams } from 'react-router-dom';
import config from '../../../config';
import usePermissions from '../../../hooks/usePermissions';

export default function Loginotp() {
    const { checkPermission, sendNotification } = usePermissions();
    const navigate = useNavigate();
    const { email, customerid } = useParams();
    const { otpLogin, resendOtp } = useAuth();
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const [time, setTime] = useState({ minutes: 1, seconds: 30 });
    const digit1Ref = useRef(null); const digit2Ref = useRef(null); const digit3Ref = useRef(null); const digit4Ref = useRef(null)
    const [allDigitsFilled, setAllDigitsFilled] = useState(false);

    const [values, setValues] = useState({
        digit1: '',
        digit2: '',
        digit3: '',
        digit4: ''
    })

    useEffect(() => {
        digit1Ref?.current?.focus()
    }, [])


    useEffect(() => {
        const interval = setInterval(() => {
            if (time?.seconds > 0) setTime(prevTime => ({ ...prevTime, seconds: prevTime?.seconds - 1 }))
            if (time?.seconds === 0) {
                if (time?.minutes === 0) clearInterval(interval);
                else setTime(prevTime => ({ ...prevTime, seconds: 59, minutes: prevTime?.minutes - 1 }))
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [time]);

    useEffect(() => {
        setAllDigitsFilled(values?.digit1 && values?.digit2 && values?.digit3 && values?.digit4);
    }, [values])

    const handleChange = (e) => {
        setErrors({})
        const values_ = { ...values }
        values_[e.target.id] = e.target.value
        setValues(values_)
        switch (e.target.id) {
            case 'digit1': return digit2Ref.current.focus()
            case 'digit2': return digit3Ref.current.focus()
            case 'digit3': return digit4Ref.current.focus()
        }
    }

    const handleSetDefault = () => {
        setValues({ ...values, digit1: '', digit2: '', digit3: '', digit4: '' })
    }

    const handleClearGoBack = () => {
        handleSetDefault()
        window.location.replace(config?.login_uri)
    }

    const handleToken = (token) => {
        localStorage.setItem(config?.tokenVar, btoa(token))
    }

    const handleNext = (response) => {
        handleToken(response?.data)
        window.location.replace(config?.base_path)
    }

    const handleResendVerificationCode = async (e) => {
        if (time?.seconds) return
        setTime({ minutes: 1, seconds: 30 })
        handleSetDefault()
        setLoading(true)
        const data_ = { email: email, customerid: customerid }
        await resendOtp(data_).then(response => handleResendResponse(response?.data)).catch(_ => handleResendErrorNext()).finally(_ => setLoading(false))
    }

    const handleResendResponse = (response) => {
        if (response.status != 200) return sendNotification({ body: `Failed to re resend verification code, ${response?.messages}`, severity: 'error' })
        return sendNotification({ body: `Verification code has been sent`, severity: 'success' })
    };

    const handleResendErrorNext = (error) => {
        return sendNotification({ body: `Failed to re resend verification code`, severity: 'error' })
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        handleSetDefault()
        const errors_ = {}
        errors_['verification_code'] = !values?.digit1 || !values?.digit2 || !values?.digit3 || !values?.digit4 ? 'Verification code is required' : false
        setErrors(errors_)
        if (Object.values(errors_).find(_ => _)) return
        setLoading(true)
        const data_ = { email: email, customerid: customerid, code: btoa(`${values?.digit1}${values?.digit2}${values?.digit3}${values?.digit4}`) }
        await otpLogin(data_).then(response => handleResponse(response?.data)).catch(_ => handleErrorNext()).finally(_ => setLoading(false))
    }

    const handleResponse = (response) => {
        if (response.status != 200) return sendNotification({ body: `Failed to verify the Email, ${response?.messages}`, severity: 'error' })
        return sendNotification({ body: `Email verified successfully`, duration: 1000, severity: 'success', actionHandler: () => handleNext(response) })
    };

    const handleErrorNext = (error) => {
        return sendNotification({ body: `Failed to verify the Email`, severity: 'error' })
    };

    return (
        <OtpVerification values={values} loading={loading} handleSubmit={handleSubmit} handleChange={handleChange} time={time} handleResendVerificationCode={handleResendVerificationCode} digit1Ref={digit1Ref} digit2Ref={digit2Ref} digit3Ref={digit3Ref} digit4Ref={digit4Ref} handleClearGoBack={handleClearGoBack} errors={errors} allDigitsFilled={allDigitsFilled} email={email} />
    )
}
import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createRoleAsync,
  deleteRoleAsync,
  getRoleDetailsAsync,
  getRolesAsync,
  updateRoleAsync,
} from "../api/roles";

const RolesContext = createContext();

export const RolesProvider = ({ children }) => {
  const dispatch = useDispatch();

  const getRolesList = async (data) => await getRolesAsync(data, dispatch);

  const createRole = async (data) => await createRoleAsync(data, dispatch);

  const getRoleDetails = async (data) =>
    await getRoleDetailsAsync(data, dispatch);

  const updateRole = async (data) => await updateRoleAsync(data, dispatch);

  const deleteRole = async (data) => await deleteRoleAsync(data, dispatch);

  const values = {
    getRolesList,
    createRole,
    getRoleDetails,
    updateRole,
    deleteRole,
  };

  return (
    <RolesContext.Provider value={values}>{children}</RolesContext.Provider>
  );
};

export default RolesContext;

import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSoftwaresAsync, createSoftwareAsync, deleteSoftwareAsync, updateSoftwareAsync, getSoftwareDetailsAsync, fetchAllSoftwaresAsync } from "../api/softwares";

const SoftwareContext = createContext();

export const SoftwareProvider = ({ children }) => {
    const dispatch = useDispatch();
    const createSoftware = async (data) => await createSoftwareAsync(data, dispatch);
    const deleteSoftware = async (data) => await deleteSoftwareAsync(data, dispatch);
    const updateSoftware = async (data) => await updateSoftwareAsync(data, dispatch);
    const getSoftwareDetails = async (data) => await getSoftwareDetailsAsync(data, dispatch);
    const fetchAllSoftwares = async (data) => await fetchAllSoftwaresAsync(data, dispatch);
    const values = { createSoftware, deleteSoftware, updateSoftware, getSoftwareDetails, fetchAllSoftwares };
    return (<SoftwareContext.Provider value={values}>{children}</SoftwareContext.Provider>);
};

export default SoftwareContext;

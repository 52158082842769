import React from "react";
import {
  CAvatar,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import User from "../assets/images/navbar/profile.png";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import Profile from "../assets/images/navbar/temporary_no_pic_avatar.jpg";
import { useSelector, useDispatch } from "react-redux";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import { SIDEBAR_ACTION } from "../store/actions";

export default function ProfileDropdown() {
  const [{ user }] = useSelector((state) => [state.authUser]);
  const { authLogout } = useAuth();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [{ unfoldable, navigations }] = useSelector((state) => [
    state.globalState,
  ]);

  const handleClick = ()=>{
    navigate("/profile")
  }
  const handleSidebar = () => {
    dispatch({
      type: SIDEBAR_ACTION,
      // payload: !unfoldable,
    });
  };

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" caret={false}>
        <div className="profile">
          <CAvatar src={user?.image_uri || Profile} />
          <div className="user-detail">
            <h2>{user?.name}</h2>
          </div>
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="profile-dropdown pt-0" placement="bottom-end">
        <Link to="/profile">
        <CDropdownItem onClick={handleSidebar}>
          <CIcon icon={cilUser} className="me-2" />
          Profile
        </CDropdownItem>
        </Link>
        <CDropdownItem href="" onClick={authLogout}>
          <CIcon icon={cilLockLocked} className="me-2" />
          Log out
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';
import { Sidebar, Header, MainPage } from './index';

const DefaultLayout = ({ isOpen }) => {
  const [{ unfoldable }] = useSelector((state) => [
    state.globalState,
  ]);

  return (
    <div>
      <Sidebar />
      <div className={`wrapper d-flex flex-column min-vh-100 bg-light ${unfoldable ? 'm-bg' : ''}`}>
        <Header />
        <div className="content flex-grow-1">
          <MainPage />
        </div>
      </div>
    </div>
  )
}

export default DefaultLayout

import React, { createContext } from "react";
import { useDispatch } from "react-redux";
import { getCalendarDataAsync, getMonthDataAsync, getWeekDataAsync } from "../api/calendar";

const CalendarContext = createContext();

export const CalendarProvider = ({ children }) => {
  const dispatch = useDispatch();

  const getCalendarData = async (data) =>
    await getCalendarDataAsync(data, dispatch);

  const getMonthData = async (data) => 
    await getMonthDataAsync(data,dispatch);

  const getWeekData = async (data) =>
  await getWeekDataAsync(data,dispatch);

  const values = {
    getCalendarData,
    getMonthData,
    getWeekData
  };

  return (
    <CalendarContext.Provider value={values}>
      {children}
    </CalendarContext.Provider>
  );
};

export default CalendarContext;

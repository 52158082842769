import React from 'react'

export default function Loader() {
  return (
    <div id="preloader">
      {/* <span class="loader"></span> */}
    </div>

  )
}

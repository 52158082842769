import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getStoragesAsync,
  storageDetailsAsync,
  storageDeleteAsync
  
} from "../api/storages";

const StorageContext = createContext();

export const StorageProvider = ({ children }) => {
  const dispatch = useDispatch();

  const getStorages = async (data) => await getStoragesAsync(data, dispatch);
  const storageDetails = async (data) => await storageDetailsAsync(data, dispatch);
  const storageDelete = async (data) => await storageDeleteAsync(data, dispatch);

  const values = {
    getStorages,
    storageDetails,
    storageDelete
  };

  return (
    <StorageContext.Provider value={values}>{children}</StorageContext.Provider>
  );
};

export default StorageContext;

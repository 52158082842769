import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getHolidaysAsync,
  createHolidayAsync,
  getHolidayDetailsAsync,
  updateHolidayAsync,
  deleteHolidayAsync,
  getHolidayInformationAsync

} from "../api/holidays";
import { HOLIDAY_NOTIFICATIONS_ACTION } from "../store/actions";

const HolidaysContext = createContext();

export const HolidaysProvider = ({ children }) => {
  const dispatch = useDispatch();

  const getHolidays = async (data) => await getHolidaysAsync(data, dispatch);

  const createHoliday = async (data) => await createHolidayAsync(data, dispatch);

  const getHolidayDetails = async (data) => await getHolidayDetailsAsync(data, dispatch);

  const updateHoliday = async (data) => await updateHolidayAsync(data, dispatch);

  const deleteHoliday = async (data) => await deleteHolidayAsync(data, dispatch);

  const closeHolidayNotification = () => {
    localStorage.setItem(btoa('jesi_holiday_popup_status'), true)
    dispatch({ type: HOLIDAY_NOTIFICATIONS_ACTION, payload: { open: true } })
  };

  const getHolidayInformation = async (data) => await getHolidayInformationAsync(data, dispatch);

  const values = {
    getHolidays,
    createHoliday,
    getHolidayDetails,
    updateHoliday,
    deleteHoliday,
    closeHolidayNotification,
    getHolidayInformation
  };

  return (
    <HolidaysContext.Provider value={values}>{children}</HolidaysContext.Provider>
  );
};

export default HolidaysContext;

import APISources from "../sources"
import instance from "../utils/axios";
import { apiCall, getUrl } from "./apicall";


export const getPartnersAsync = async (data, dispatch) => await instance.post(`${APISources().Partners.ListURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const createPartnerAsync = async (data, dispatch) => await instance.post(`${APISources().Partners.CreateURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getPartnerDetailsAsync = async (data, dispatch) => await instance.post(`${APISources().Partners.DetailsURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const updatePartnerAsync = async (data, dispatch) => await instance.post(`${APISources().Partners.UpdateURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const deletePartnerAsync = async (data, dispatch) => await instance.post(`${APISources().Partners.DeleteURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const UploadPartnerFileAsync = async (data, dispatch) => await instance.post(`${APISources().Partners.UploadFileURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getPartnerServicesAsync = async (data, dispatch) => await instance.post(`${APISources().Partners.PartnerServicesURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getAddonsAsync = async (data, dispatch) => await instance.post(`${APISources().Partners.AddonsURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const createFileAsync = async (data, dispatch) => await instance.post(`${APISources().Partners.CreateFileDataURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const deleteFileAsync = async (data, dispatch) => await instance.post(`${APISources().Partners.RemoveFileDataURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const serviceDetailsAsync = async (data, dispatch) => await instance.post(`${APISources().Partners.ServiceDetailURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })

//export const getSoftwaresByServiceAsync = async (data, dispatch) => await instance.post(`${APISources().Partners.SoftwaresByServiceURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getPriceBySortwareAsync = async (data, dispatch) => await instance.post(`${APISources().Partners.PriceBySortwareURI}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
export const getSoftwaresByServiceAsync = async (data, dispatch) => await instance.get(`${APISources().Partners.SoftwaresByServiceURI}/${data?.id}`, data, { signal: data?.signal, cancelToken: data?.cancelToken })
